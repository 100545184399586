import React, { useState, useRef, useEffect } from "react";
import { ChatContainer, MessageList, Message, MessageInput } from "@chatscope/chat-ui-kit-react";
import { IconButton, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SendIcon from "@mui/icons-material/Send";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Modal from "../../common/modal/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../common/button/Button";
import CameraComponent from "./components/camera";
import { CONSTANTS } from "../../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { commonActions } from "../../../store/features/commonSlice";
import { useTranslation } from "react-i18next";
import { assetPath } from "../../../utils/assetPath";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CompleteConsultationService from "../../../modules/CSR/services/completeConsultaionService";
import { ROUTES } from "../../../constants/routes";
import { useNavigate } from "react-router-dom";

interface ChatMessage {
    message: string;
    sender: string;
    type?: "text" | "image"; // Optional type property
    img: string;
}

// Type definitions for SpeechRecognition API
interface SpeechRecognitionResult {
    transcript: string;
    confidence: number;
}

interface SpeechRecognitionEvent {
    results: SpeechRecognitionResult[][];
    resultIndex: number;
}
/**
 * ChatScreen common component can be used for getting chat feature
 * @param props - Require isChatScreenOpen, isInsightAvailable, setIsInsightOpen, setIsChatScreenOpen, setIsFeedbackOpen, setIsInsightAvailable, messages, setMessages
 * @returns - Chat screen with Conversation feature having different button section at footer
 */
const ChatScreen = (props: any) => {
    const { isChatScreenOpen, isInsightAvailable, setIsInsightOpen, setIsChatScreenOpen, setIsFeedbackOpen, setIsInsightAvailable, messages, setMessages, userRole, specialityValue, setIsChatStart , chatSessionData} = props;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState<string>("");
    const [cameraOpen, setCameraOpen] = useState<boolean>(false);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [recognition, setRecognition] = useState<any>(null);
    const [isRecording, setIsRecording] = useState(false);

    const videoRef: any = useRef(null);
    const fileInputRef: any = useRef(null);

    /* used to retrieve the currentConsultation data from the Redux store's common state */
    const currentConsultation = useAppSelector(
        (state) => state.common.currentConsultation
    );

    useEffect(() => {
        // Initialize speech recognition
        const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
        if (SpeechRecognition) {
            const recog = new SpeechRecognition();
            recog.continuous = false;
            recog.interimResults = false;
            recog.onresult = (event: SpeechRecognitionEvent) => {
                const transcript = event.results[0][0].transcript;
                setInputValue(transcript); // Set recognized speech to input value
            };
            recog.onerror = (event: Error) => {
                console.error("Speech recognition error: ", event);
            };
            recog.onend = () => {
                setIsRecording(false);
            };
            setRecognition(recog);
        } else {
            console.warn("Speech recognition not supported in this browser.");
        }
    }, []);

    const handleInsightModel = () => {
        setIsChatScreenOpen(false);
        setIsInsightOpen(true);
    };

    const handleSend = () => {
        if (inputValue.replace(/&nbsp;/g, "").trim() === "") return;        
        if(setIsChatStart){
            setIsChatStart(true);
        }
        const newMessages: Array<ChatMessage> = [...messages, { message: inputValue, sender: "user", type: "text" }];
        setMessages(newMessages);
        setIsRecording(false);
        setInputValue("");
        setTimeout(() => {
            const assistantMessage = "This is a simulated response based on your message.";
            setMessages((prevMessages: Array<ChatMessage>) => [...prevMessages, { message: assistantMessage, sender: "Assistant", type: "text", img: assetPath("images/chat/assistant-profile-pic.svg") }]);
        }, 1000);
    };

    const handleCameraOpen = () => {
        setCameraOpen(true);
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                }
            })
            .catch((err) => {
                console.error("Error accessing the camera: ", err);
            });
    };

    const triggerFileInput = () => {
        fileInputRef.current?.click();
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === "application/pdf") {
            const newMessages: Array<ChatMessage> = [...messages, { message: `Uploaded file: ${file.name}`, sender: "user", type: "text" }];
            setMessages(newMessages);
            fileInputRef.current.value = "";
        } else {
            alert(t("chat_session.pdf_file"));
        }
    };

    const handleMic = () => {
        if (recognition) {
            if (isRecording) {
                recognition.stop();
                setIsRecording(false);
            } else {
                recognition.start(); // Start speech recognition
                setIsRecording(true);
            }
        }
    };

    useEffect(() => {
        if (isInsightAvailable) {
            dispatch(
                commonActions.SHOW_SNACKBAR({
                    show: true,
                    message: (t("chat_history.insight_created"))
                })
            );
        }
    }, [isInsightAvailable, dispatch, t]);

    const handleIncompleteChat = () => {
        if (isInsightAvailable) {
            setIsFeedbackOpen(false);
            // Removing saved Insight here on close of insight chat screen
            setIsInsightAvailable(false);
        } else {
            setIsFeedbackOpen(true);
        }
        if (userRole === CONSTANTS.MEDICAL_PROF) {
            setIsFeedbackOpen(false);
        }
        setIsChatScreenOpen(false);
    };

    const handleCloseChat = () => {
        setIsChatScreenOpen(false);
    };

    const handleCompleteChat = () => {

        const payload ={
            "id": currentConsultation?.consultationId,
            "visitId": chatSessionData?.visitId,
            "order": currentConsultation?.consulationOrder
        };
    
        new CompleteConsultationService(payload).completeConsultaion()
            .then((res: any) => {
                setIsChatScreenOpen(false);
                navigate("/csr"+ROUTES.COMMON.USER_VERIFICATION);
            })
            .catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err.message,
                        success: false
                    })
                );
            });
    };

    return (
        <Modal
            open={isChatScreenOpen}>
            {/* onClose={handleClose}> */}
            <Box sx={{
                width: "100%",
                bgcolor: "background.paper",
                boxShadow: 24,
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px !important"
            }}>
                <Grid container sx={{ px: "10px" }}>
                    <Grid size={{ xs: 6, sm: 6, lg: 2, xl: 2 }}>
                        <IconButton sx={{ color: "black" }}>
                            <Typography>{specialityValue}</Typography>
                        </IconButton>
                    </Grid>
                    <Grid size={{ xs: 6, sm: 6, lg: 10, xl: 10 }}>
                        <Box sx={{ display: "flex", justifyContent: "end", flexDirection: "row" }}>
                            {userRole === CONSTANTS.CUSTOMER_SUPPORT && <>
                                {isInsightAvailable &&
                                    <IconButton onClick={handleCompleteChat} sx={{ color: "black" }}>
                                        <CheckCircleIcon />
                                    </IconButton>
                                }
                                <IconButton onClick={handleIncompleteChat} sx={{ color: "black" }}>
                                    <ExitToAppIcon />
                                </IconButton>
                            </>}
                            <IconButton onClick={handleCloseChat} sx={{ color: "black" }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>

                <ChatContainer style={{ flex: 1 }}>
                    {/* {messages.map((msg: ChatMessage, index: number) => {
                        return (

                        );
                    })} */}
                    <MessageList className="wpx_chat_size_color" >
                        {messages.map((msg: ChatMessage, index: number) => {
                            const messageContent = msg.type === "image"
                                ? <img src={msg.message} alt="Captured" style={{ maxWidth: "100%" }} />
                                : msg.message;

                            return (
                                <>
                                    {msg.sender === "Assistant" ? (
                                        <Box key={index} sx={{ display: "flex", gap: "5px", paddingTop: "10px" }}>
                                            <img src={msg.img} alt={msg.sender} /><Typography>{msg.sender}</Typography>
                                        </Box>
                                    ) : (
                                        <Box key={index} sx={{ display: "flex", gap: "5px", paddingTop: "10px", justifyContent: "flex-end" }}>
                                            <Typography>User</Typography><AccountCircleRoundedIcon />
                                        </Box>
                                    ) }
                                    <Message
                                        key={index}
                                        model={{
                                            position: "single",
                                            message: typeof messageContent === "string" ? messageContent : "",
                                            sentTime: "just now",
                                            sender: msg.sender,
                                            direction: msg.sender === "user" ? "outgoing" : "incoming",
                                        }}
                                    >
                                        {msg.type === "image" && messageContent} {/* Render image as children */}
                                    </Message>
                                </>
                            );
                        })}
                    </MessageList>
                </ChatContainer>
                {/* Chat Model footer Button Section */}
                {/* Show Insight Button if it is available else show chat Input box */}
                {isInsightAvailable ?
                    (
                        userRole === CONSTANTS.CUSTOMER_SUPPORT ? (
                            <Box style={{ padding: "10px", textAlign: "center" }}>
                                <span>Session Completed!</span>
                            </Box>
                        ) : (
                            <Box style={{ display: "flex", alignItems: "center", padding: "10px", gap: "10px" }}>
                                <Button
                                    className="wpx_next__btn"
                                    label="View Insight"
                                    variant="contained"
                                    onClick={handleInsightModel}
                                />
                            </Box>)
                    ) : (
                        <Grid container sx={{ p: "10px" }}>
                            <IconButton onClick={triggerFileInput}>
                                <AttachFileIcon fontSize="large" className="wpx_icon_color" />
                            </IconButton>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept="application/pdf"
                                onChange={handleFileUpload}
                            />
                            <IconButton onClick={handleCameraOpen} >
                                <PhotoCameraIcon fontSize="large" className="wpx_icon_color" />
                            </IconButton>
                            <IconButton onClick={handleMic} style={{ color: isRecording ? "red" : "inherit" }} >
                                <MicNoneIcon fontSize="large" />
                            </IconButton>
                            <MessageInput
                                attachButton={false}
                                sendButton={false}
                                placeholder={t("chat_session.message")}
                                style={{ flex: 1 }}
                                value={inputValue}
                                onChange={(value) => setInputValue(value)}
                                className="wpx_start_chat_bg_color"
                            />
                            <IconButton onClick={handleSend} >
                                <SendIcon fontSize="large" className="wpx_icon_color" />
                            </IconButton>
                        </Grid>
                    )}
            </Box>
            {/* Camera Component for Button action - Having Capture and Upload Feature */}
            {cameraOpen && <CameraComponent
                capturedImage={capturedImage}
                setCapturedImage={setCapturedImage}
                videoRef={videoRef}
                messages={messages}
                setMessages={setMessages}
                cameraOpen={cameraOpen}
                setCameraOpen={setCameraOpen}
            />}
        </Modal >
    );
};

export default ChatScreen;
