import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import MultipleOrganization from "./pages/MultipleOrganization";
import NoOrganization from "./pages/NoOrganization";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { commonActions } from "../../../store/features/commonSlice";
import { CONSTANTS } from "../../../constants/constants";
import OrganizationService from "./service/organizationSelectionService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

const OrganizationSelection = () => {

    const userRole = useAppSelector((state) => state.common.userRole);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [orgList, setOrgList] = useState<any>([]);

    useEffect(() => {
        organizationList();
    }, []);
    const organizationList = () => {
        const payload = {};
        new OrganizationService(payload).organizationList()
            .then((res) => {
                setOrgList(res);
            }).catch((err) => {
                console.log(err);
            });
    };

    useEffect(()=>{
        if(orgList?.length === 1 ){
            dispatch(commonActions.IS_LOGIN(true));
            // Dispatch Current Organization and Select the Single Organization
            dispatch(commonActions.CURRENT_ORG_LIST({
                orgList: orgList,
                selectOrg: orgList[0]
            }));
            //Redirect the page since single Org exist
            if (userRole === CONSTANTS.ORG_ADMIN) {
                navigate(ROUTES.OA.MANAGE_MP);
            } else if(userRole === CONSTANTS.MEDICAL_PROF){
                navigate(ROUTES.MP.ACTIVE_SESSION_LIST);
            } else {
                navigate("/csr" + ROUTES.COMMON.USER_VERIFICATION);
            }
        }
    },[orgList, dispatch, userRole, navigate]);

    return (
        <Container className="wpx_organizationSelection">
            {
                orgList ?
                    orgList?.length ? (
                        <MultipleOrganization organizationList={orgList} />
                    ) : (
                        <NoOrganization />
                    ):
                    null
            }
        </Container>
    );
};

export default OrganizationSelection;
