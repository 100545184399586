import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton } from "@mui/material";
import { CONSTANTS } from "../../constants/constants";
import { useAppSelector } from "../../store/hooks";
import { MPMenuConfig, orgAdminMenuConfig, superAdminMenuConfig } from "../../config/menu.config";

export default function SideNavbar() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }
            setIsOpen(open);
        };
    const location = useLocation();
    const userRole = useAppSelector((state) => state.common.userRole);
    const menuConfig = userRole === CONSTANTS.SUPER_ADMIN ? superAdminMenuConfig  : userRole === CONSTANTS.ORG_ADMIN ? orgAdminMenuConfig : userRole === CONSTANTS.MEDICAL_PROF ? MPMenuConfig : null;

    function handleClickClose() {
        setIsOpen(false);
    }

    const list = (
        <>
            <ListItem
                onClick={handleClickClose}
                sx={{ backgroundColor: "#111827", height: "7.4vh" }}
            >
                <IconButton sx={{ color: "#ffffff" }}>
                    <CloseRoundedIcon />
                </IconButton>
            </ListItem>
            {
                userRole === CONSTANTS.SUPER_ADMIN || userRole === CONSTANTS.ORG_ADMIN || userRole === CONSTANTS.MEDICAL_PROF ?
                    <List component="nav" disablePadding className="wpx_navbar">

                        {menuConfig?.map((menuItem: any, index: any) => {
                            const isActive = location.pathname === menuItem.path;
                            return (
                                <Link key={index} to={menuItem.path} onClick={() => setIsOpen(false)}>
                                    <ListItem className={isActive ? "" : "wpx_navbar__active_link"} component="div">
                                        <ListItemText primary={menuItem.name} />
                                    </ListItem>
                                </Link>
                            );
                        })}

                    </List>
                    : null
            }
        </>
    );

    return (
        menuConfig ? (
            <>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => setIsOpen(!isOpen)}
                > 
                    <MenuIcon />
                    <Drawer
                        open={isOpen}
                        onClose={toggleDrawer(false)}
                    >
                        {list}
                    </Drawer>
                </IconButton>
            </>
        ) : null

    );
}