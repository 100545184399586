import { ROUTES } from "../constants/routes";

export const superAdminMenuConfig = [
    {
        name: "Manage Organizations",
        path: ROUTES.SA.MANAGE_ORG
    }
];

export const orgAdminMenuConfig = [
    {
        name: "Manage MP",
        path: ROUTES.OA.MANAGE_MP
    },
    {
        name: "Manage CSR",
        path: ROUTES.OA.MANAGE_CSR
    },
];

export const MPMenuConfig = [
    {
        name: "Active Sessions",
        path: ROUTES.MP.ACTIVE_SESSION_LIST
    },
    {
        name: "Walk in",
        path: "/medprof"+ROUTES.COMMON.USER_VERIFICATION
    }
];