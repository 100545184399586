import React, { useState } from "react";
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    Collapse,
    Box
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Table = (props: any) => {
    const {
        data,
        columns,
        requireCheckbox = false,
        onEdit,
        onDelete,
        onNavigate,
        setSelectedChatHistory,
        handleCustomAction
    } = props;

    const [openRow, setOpenRow] = useState<any>(null);
    const [selectedCheckBox, setSelectedCheckbox] =useState<any>(null);

    const handleExpandClick = (index: number) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleCheckboxClick = (e:any, index: number, row:any) => {
        if(index === selectedCheckBox){
            setSelectedCheckbox(null);
            setSelectedChatHistory(null);
        }else{
            setSelectedCheckbox(index);
            setSelectedChatHistory(row);
        }
    };

    return (
        <TableContainer component={Paper}>
            <MUITable>
                <TableHead className="wpx_table_head_style">
                    <TableRow>
                        {requireCheckbox ?
                            <TableCell>
                            </TableCell>
                            : null
                        }
                        {columns?.map((column: any) => (
                            <TableCell
                                key={column.id as string}
                                sx={{ width: column.width || "auto" }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row : any, index: number) => {
                        const subHistoryData : any  = row?.subHistory || null;
                        return(
                            <>
                                <TableRow key={index} className="wpx_table_body_style">
                                    {requireCheckbox ?
                                        <TableCell>
                                            {!(subHistoryData?.length > 0) ?
                                                <Checkbox
                                                    size= "small"
                                                    checked = {selectedCheckBox === row?.id }
                                                    className = "tableCheckbox"
                                                    onChange = {(e)=> handleCheckboxClick(e, row?.id, row)}/>
                                                :
                                                null
                                            }
                                        </TableCell>
                                        : null
                                    }
                                    {columns?.map((column: any) => {
                                        return(
                                            <TableCell
                                                key={String(column.id)}
                                                sx={{ width: column.width || "auto" }}
                                                className={column?.className}
                                            >
                                                {column.id === "actions" ? (
                                                    <>
                                                        {onEdit && (
                                                            <IconButton onClick={() => onEdit(row)}>
                                                                <EditIcon className="wpx_action__btn_icon"/>
                                                            </IconButton>
                                                        )}
                                                        {onDelete && (
                                                            <IconButton onClick={() => onDelete(row)}>
                                                                <DeleteIcon className="wpx_action__btn_icon"/>
                                                            </IconButton>
                                                        )}
                                                        {onNavigate && (
                                                            <IconButton onClick={() => onNavigate(row)}>
                                                                <VisibilityIcon className="wpx_action__btn_icon"/>
                                                            </IconButton>
                                                        )}
                                                        {!subHistoryData && handleCustomAction ? handleCustomAction(row) : null }
                                                        {subHistoryData && (
                                                            subHistoryData?.length > 0 ?
                                                                <IconButton onClick={() => handleExpandClick(index)}>
                                                                    {openRow === index ? <RemoveIcon/>: <AddIcon />}
                                                                </IconButton> :  null
                                                        )}
                                                        { subHistoryData?.length === 0 && handleCustomAction ? handleCustomAction(row) : null}
                                                    </>
                                                ) : (
                            row[column.id] as React.ReactNode
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                { subHistoryData?.length !== 0 ? (
                                    <TableRow  sx={{ backgroundColor: "#f3f4f5" }}>
                                        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                <Box margin={2}>
                                                    <MUITable size="small" aria-label="nested table">
                                                        <TableBody>
                                                            {subHistoryData?.map((subRow: any, index:number)=>{
                                                                return(
                                                                    <TableRow key={index} className="wpx_table_body_style">
                                                                        {requireCheckbox ?
                                                                            <TableCell sx ={{ paddingLeft :"35px", borderBottom: "none" }}>
                                                                                <Checkbox
                                                                                    checked = {selectedCheckBox === subRow?.id }
                                                                                    className="tableCheckbox"
                                                                                    onChange = {(e)=> handleCheckboxClick(e, subRow?.id, subRow)}/>

                                                                            </TableCell>
                                                                            : null
                                                                        }
                                                                        {columns?.map((column: any) => {
                                                                            return(
                                                                                <TableCell
                                                                                    key={String(column.id)}
                                                                                    sx={{ width: column.width || "auto", paddingLeft: "30px", borderBottom: "none" }}  // Set width if provided

                                                                                >
                                                                                    {column.id === "actions" ? (
                                                                                        handleCustomAction ? handleCustomAction(subRow) : null
                                                                                    ) : (
                                                                subRow[column.id] as React.ReactNode
                                                                                    )}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </MUITable>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                            </>
                        );
                    })}
                </TableBody>
            </MUITable>
        </TableContainer>
    );
};

export default Table;
