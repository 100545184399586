import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/common/input/InputField";
import { ROUTES } from "../../../constants/routes";
import Button from "../../../components/common/button/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CONSTANTS } from "../../../constants/constants";
import UserVerificationService from "../../CSR/services/userVerificationService";
import { commonActions } from "../../../store/features/commonSlice";


const UserVerification = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userRole = useAppSelector((state) => state.common.userRole);

    const [showOtpComp, setShowOtpComp] = useState(false);
    const [aadhaar, setAadhaar] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [mobile, setMobile] = useState<string>("");
    const [otp, setOtp] = useState<string>("");
    const [dobAadhar, setDobAadhar] = useState<string>("");
    const [dobMobile, setDobMobile] = useState<string>("");
    const [showAadhaarValidation, setShowAadhaarValidation] = useState<boolean>(false);
    const [showNameValidation, setShowNameValidation] = useState<boolean>(false);
    const [showMobileValidation, setShowMobileValidation] = useState<boolean>(false);
    const [showOtpValidation, setShowOtpValidation] = useState<boolean>(false);
    const [showDobAadhaarValidation, setShowDobAadhaarValidation] = useState<boolean>(false);
    const [showDobMobileValidation, setShowDobMobileValidation] = useState<boolean>(false);
    const [verifyAadhaar, setVerifyAadhaar] = useState<boolean>(true);


    const handleInput = (inputVal: React.ChangeEvent<HTMLInputElement>) => {
        if (inputVal.target.name === "aadhaarNumber") {
            const pattern = /^\d{0,12}$/;
            if (pattern.test(inputVal.target.value)) {
                setAadhaar(inputVal.target.value);
                setShowAadhaarValidation(true);
                setShowMobileValidation(false);
                setShowNameValidation(false);
                setShowDobMobileValidation(false);
                if (inputVal?.target?.value?.length > 11) {
                    setShowAadhaarValidation(false);
                }
            }
        } else if (inputVal.target.name === "email") {
            const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (pattern.test(inputVal.target.value)) {
                setEmail(inputVal.target.value);
                setShowAadhaarValidation(false);
                setShowDobAadhaarValidation(false);
            }
        } else if (inputVal.target.name === "mobile") {
            const pattern = /^\d{0,10}$/;
            if (pattern.test(inputVal.target.value)) {
                setMobile(inputVal.target.value);
                setShowMobileValidation(true);
                setShowNameValidation(true);
                setShowAadhaarValidation(false);
                setShowDobAadhaarValidation(false);
                if (inputVal?.target?.value?.length > 9) {
                    setShowMobileValidation(false);
                }
            }
        } else if (inputVal.target.name === "otp") {
            const pattern = /^\d{0,6}$/;
            if (pattern.test(inputVal.target.value)) {
                setOtp(inputVal.target.value);
                setShowOtpValidation(true);
                if (inputVal?.target?.value?.length > 5) {
                    setShowOtpValidation(false);
                }
            }
        }
    };

    const apiCall = (payload: any) => {
        new UserVerificationService(payload).verify()
            .then((res) => {
                if(userRole === CONSTANTS.MEDICAL_PROF){
                    navigate(ROUTES.MP.USER_CHAT_HISTORY);
                }else{
                    if (!res) {
                        navigate(ROUTES.CSR.USER_REGISTRATION, {
                            state: {
                                from: "registration",
                                editData: {
                                    aadhaar: aadhaar,
                                    email: email,
                                    mobile: mobile,
                                    dob: dobAadhar
                                }
                            }
                        });
                    } else {
                        navigate(ROUTES.CSR.PATEINT_CHAT_HISTORY);
                    }
                }             
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err?.message,
                        success: false
                    })
                );
            });
    };

    /* When the OTP section becomes visible, this effect scrolls the page to the bottom */
    useEffect(() => {
        if (showOtpComp) {
            window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
        }
    }, [showOtpComp]);

    const handleAadhaarNext = () => {
        if (aadhaar.length === 12 && dobAadhar && userRole !== CONSTANTS.MEDICAL_PROF) {
            setShowOtpComp(true);
            setVerifyAadhaar(true);
        } else if (aadhaar.length === 12 && dobAadhar && userRole === CONSTANTS.MEDICAL_PROF) {
            const payload = {
                aadhaarverification: true,
                aadhaar: aadhaar,
                dob: dobAadhar
            };
            apiCall(payload);
        }
    };

    const handleNameMobileNext = () => {
        if (mobile.length === 10 && dobMobile && userRole !== CONSTANTS.MEDICAL_PROF) {
            setShowOtpComp(true);
            setVerifyAadhaar(false);
        }
        if (mobile.length === 10 && dobMobile && userRole === CONSTANTS.MEDICAL_PROF) {
            const payload = {
                aadhaarverification: false,
                email: email,
                dob: dobMobile,
                phone: mobile
            };
            apiCall(payload);
        }
    };

    const handleDobAadhaar = (event: SelectChangeEvent<string>) => {
        setDobAadhar(event.target.value);
        setShowDobAadhaarValidation(true);
        setShowDobMobileValidation(false);
        if (event.target.value) {
            setShowDobAadhaarValidation(false);
            setShowDobMobileValidation(false);
        }
    };

    const handleDobMobile = (event: SelectChangeEvent<string>) => {
        setDobMobile(event.target.value);
        setShowDobMobileValidation(true);
        setShowAadhaarValidation(false);
        setShowDobAadhaarValidation(false);
        if (event.target.value) {
            setShowDobMobileValidation(false);
            setShowAadhaarValidation(false);
        }
    };

    const handleUserVerification = () => {
        if (otp.length === 6) {
            let payload = {};
            if (verifyAadhaar) {
                payload = {
                    aadhaarverification: verifyAadhaar,
                    aadhaar: aadhaar,
                    dob: dobAadhar
                };
            } else {
                payload = {
                    aadhaarverification: verifyAadhaar,
                    email: email,
                    dob: dobMobile,
                    phone: mobile
                };
            }
            apiCall(payload);           
        }
    };


    return (
        <>
            <Box className="wpx_verification__wrapper">
                <Grid container spacing={2} sx={{ my: 2, ml: 8 }}>
                    <Grid size={{ xs: 10, sm: 8, md: 6, lg: 4 }}>
                        <Box>
                            <Box sx={{ my: 2 }}>
                                <Typography component="h1">{t("user_verification.start_user_verification")}</Typography>
                                <Typography>{t("user_verification.select_user_verification")}</Typography>
                            </Box>
                            <InputField
                                placeHolder={t("user_verification.aadhaar_number")}
                                variant="outlined"
                                className="wpx_verification__wrapper__input"
                                name="aadhaarNumber"
                                value={aadhaar}
                                onChange={handleInput}
                                disabled={(email || mobile || dobMobile) ? true : false}
                            />
                            {
                                showAadhaarValidation ?
                                    <Typography color="error" sx={{ ml: 2 }}>
                                        {"Please fill 12 digit aadhaar no."}
                                    </Typography> : null
                            }

                            <InputField
                                type="text"
                                value={dobAadhar}
                                name="dob"
                                placeHolder="Enter date of birth"
                                onFocus={(e: any) => {
                                    e.target.type = "date";
                                    e.target.max = new Date().toISOString().split("T")[0];
                                }}
                                onBlur={(e: any) => { if (!e.target.value) e.target.type = "text"; }}
                                onChange={(e) => handleDobAadhaar(e)}
                                className="wpx_verification__wrapper__input"
                                disabled={(email || mobile || dobMobile) ? true : false}
                                onKeyDown={(e: any) => e.preventDefault()}
                            />

                            {
                                showDobAadhaarValidation ?
                                    <Typography color="error" sx={{ ml: 2, mt: 1 }}>
                                        {t("new_user.dob")}
                                    </Typography> : null
                            }

                            <Button
                                className={`wpx_verification__wrapper__disabledBtn ${aadhaar?.length > 11 && dobAadhar ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                onClick={handleAadhaarNext}
                                variant="contained"
                                label={t("common.next")}
                            />
                        </Box>

                        <Typography className="wpx_verification__wrapper__middle_gap">Or</Typography>

                        <Box>

                            <InputField
                                placeHolder="Enter email"
                                variant="outlined"
                                className="wpx_verification__wrapper__input"
                                name="email"
                                value={email}
                                disabled={aadhaar || dobAadhar ? true : false}
                                onChange={handleInput}
                            />
                            {
                                showNameValidation && !email ?
                                    <Typography color="error" sx={{ ml: 2 }}>
                                        {t("new_user.fill_name")}
                                    </Typography> : null
                            }
                            <InputField
                                placeHolder="Enter mobile number"
                                variant="outlined"
                                className="wpx_verification__wrapper__input"
                                name="mobile"
                                value={mobile}
                                disabled={aadhaar || dobAadhar ? true : false}
                                onChange={handleInput}
                            />
                            {
                                showMobileValidation ?
                                    <Typography color="error" sx={{ ml: 2 }}>
                                        {t("new_user.mobile_number")}
                                    </Typography> : null
                            }

                            <InputField
                                type="text"
                                value={dobMobile}
                                name="dob"
                                placeHolder={t("new_user.date_of_birth")}
                                onFocus={(e: any) => {
                                    e.target.type = "date";
                                    e.target.max = new Date().toISOString().split("T")[0];
                                }}
                                onBlur={(e: any) => { if (!e.target.value) e.target.type = "text"; }}
                                onChange={(e) => handleDobMobile(e)}
                                className="wpx_verification__wrapper__input"
                                disabled={aadhaar || dobAadhar ? true : false}
                                onKeyDown={(e: any) => e.preventDefault()}
                            />

                            {
                                showDobMobileValidation ?
                                    <Typography color="error" sx={{ ml: 2, mt: 1 }}>
                                        {t("new_user.dob")}
                                    </Typography> : null
                            }
                            <Button
                                className={`wpx_verification__wrapper__disabledBtn ${mobile?.length > 9 && email && dobMobile ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                // disabled={!name || !mobile}
                                onClick={handleNameMobileNext}
                                variant="contained"
                                label="Next"
                            />
                        </Box>

                        {
                            showOtpComp && (<Box>
                                <Box sx={{ my: 2 }}>
                                    <Typography component="h1">{t("user_verification.verify_communications")}</Typography>
                                    <Typography>{t("user_verification.high_security_msg")}</Typography>
                                </Box>

                                <InputField placeHolder="Enter otp" variant="outlined" className="wpx_verification__wrapper__input" name="otp" value={otp} onChange={(e) => handleInput(e)} />
                                {
                                    showOtpValidation ?
                                        <Typography color="error" sx={{ ml: 2 }}>
                                            {t("new_user.fill_otp")}
                                        </Typography> : null
                                }
                                <Button
                                    className={`wpx_verification__wrapper__disabledBtn ${otp?.length > 5 ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                    disabled={!otp}
                                    variant="contained"
                                    label={t("common.next")}
                                    onClick={handleUserVerification}
                                />
                            </Box>)
                        }
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default UserVerification;