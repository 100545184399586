import { UserType } from "../enum/UserTypes";

export const CONSTANTS = {
    SUPER_ADMIN: UserType.USER_TYPE_SA,
    ORG_ADMIN: UserType.USER_TYPE_OA,
    CUSTOMER_SUPPORT: UserType.USER_TYPE_CSR,
    MEDICAL_PROF: UserType.USER_TYPE_MP,
    PAGINATION_LIMIT: 15,
    FIRST_PAGE:1,
    EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,

};

