export const mockAPIPath = {
    ACTIVE_SESSION_LIST: "/json-schemas/ActiveSessionList.json",
    USER_CHAT_HISTORY_LIST: "/json-schemas/ChatHistoryList.json",
    SPECIALITY_LIST: "/json-schemas/SpecialityList.json",
    VERIFY_USER:"/json-schemas/CommonPostResponse.json",
    CREATE_USER:"/json-schemas/CommonPostResponse.json", 
    CREATE_VISIT_LIST: "/json-schemas/CommonPostResponse.json",
    NEW_CONSULTATION_LIST: "/json-schemas/CommonPostResponse.json",
    COMPLETE_CONSULTATION_LIST: "/json-schemas/CommonPostResponse.json",
};

export const serverAPIPath = {
    VERIFY_USER: "/identities/find", 
    CREATE_USER: "/identities/create",
    CREATE_VISIT_LIST: "/visits/create",
    NEW_CONSULTATION_LIST: "/consultations/create",
    COMPLETE_CONSULTATION_LIST: "/consultations/complete",
    REFRESH_LOGIN: "/auth/refresh"
};