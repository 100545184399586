export const ROUTES = {
    ROOT: "/",
    UNDEFINED: "*",
    COMMON: {
        BASE: "/",
        USER_VERIFICATION: "/user/verification",
        ORGANIZATION_SELECTION: "/organization/select",
    },
    SA: {
        BASE: "/superadmin",
        MANAGE_ORG: "/superadmin/manage/org",
        MANAGE_OA: "/superadmin/manage/oa"
    },
    OA: {
        BASE: "/orgadmin",
        MANAGE_MP: "/orgadmin/manage/mp",
        MANAGE_CSR: "/orgadmin/manage/csr"
    },
    MP: {
        BASE: "/medprof",
        ACTIVE_SESSION_LIST: "/medprof/active/session",
        USER_CHAT_HISTORY: "/medprof/user/chat/history",
        REFER_SPECIALIST: "/medprof/refer/specialist",
        USER_CONSULTATION_LIST: "/medprof/consultation/list"
    },
    CSR: {
        BASE: "/csr",
        SELECT_SPECIALITY: "/csr/select/speciality",
        ACTIVE_SESSION: "csr/active-session",
        USER_REGISTRATION: "/csr/user/registration",
        PATEINT_CHAT_HISTORY: "/csr/pateint/history"
    }
};