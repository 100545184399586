interface CommonType {
    userRole?: number | null;
    isLogin?: boolean,
    userDetails?: {
        name?: string,
        userRole?: number | null,
        profile?: string,
        speciality?: string,
        memberIds?: Array<string> | null,
        cid?: string | null,
        orgIds?: Array<string> | null,
        specialityIds?: Array<string> | null
    }
    curentOrgList?: {
        orgList?: Array<{name: string, location: string, id: string}>,
        selectOrg?: {name: string, location: string, id: string},
    }
    snackbarInfo : {
        show: boolean,
        message: string,
        success?: boolean,
    },
    currentConsultation: {
        visitId?: string,
        consultationId?: string,
        consulationOrder?: string,
    }
}

export const commonInitialValue: CommonType = {
    userRole: null,
    isLogin: false,
    userDetails: {
        name: "",
        userRole: null,
        profile: "",
        speciality: "",
        memberIds:  null,
        cid: null,
        orgIds: null,
        specialityIds: null
    },
    curentOrgList: {
        orgList: [{name: "", location: "", id: ""}],
        selectOrg: {name: "", location: "", id: ""}
    },
    snackbarInfo: {
        show: false,
        message: "Snackbar Message",
        success: false,
    },
    currentConsultation: {
        visitId: "",
        consultationId: "",
        consulationOrder: "",
    }
};

export default CommonType;
