import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InputField from "../../common/input/InputField";
import Button from "../../common/button/Button";
import Select from "../../common/input/Select";
import DialogModal from "../../common/modal/DialogModal";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ChatSession from "..";
import { specialityListType } from "../../../types/CommonType";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import useAxios from "../../../hooks/useAxios";
import { mockAPIPath } from "../../../modules/CSR/config/apiPath";
import VisitModalService from "../../../modules/CSR/services/VisitModalService";
import { commonActions } from "../../../store/features/commonSlice";


const VisitModal = (props: any) => {

    const { openVisitModal, setOpenVisitModal, setOpenSpecialityModal, chatSessionData, setChatSessionData } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [reason, setReason] = useState<string>("");
    const [age, setAge] = useState<number | null>(null);
    const [weight, setWeight] = useState<number | null>(null);
    const [height, setHeight] = useState<number | null>(null);
    const [bloodGroup, setBloodGroup] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [startConversation, setStartConversation] = useState<boolean>(false);
    const [specialityList, setSpecialtyList] = useState<specialityListType>([]);
    const userRole = useAppSelector((state) => state.common.userRole);
    const [isChatScreenOpen, setIsChatScreenOpen] = useState<boolean>(false);

    const [reasonValidation, setReasonValidation] = useState<boolean>(false);

    const { data: specialityListApiData } = useAxios({
        url: `${mockAPIPath.SPECIALITY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        setSpecialtyList(specialityListApiData?.data);
    }, [specialityListApiData]);

    const bloodGroupArr = [
        { value: "A+", label: "A+" },
        { value: "A-", label: "A-" },
        { value: "B+", label: "B+" },
        { value: "B-", label: "B-" },
        { value: "O+", label: "O+" },
        { value: "O-", label: "O-" },
        { value: "AB+", label: "AB+" },
        { value: "AB-", label: "AB-" }
    ];

    const genderArr = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
    ];

    const handleWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWeight(Number(e.target.value));
    };

    const handleHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHeight(Number(e.target.value));
    };

    const handleAge = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAge(Number(e.target.value));
    };

    const handleBloodGroup = (e: any) => {
        setBloodGroup(e.target.value);
    };

    const handleNext = () => {

        if(!reason){
            setReasonValidation(true);
            return;
        }

        const payload = {
            "reasonForVisit": reason,
            "follow": chatSessionData?.visitId,
            "age": age,
            "gender": gender,
            "height": height,
            "weight": weight,
            "bloodType": bloodGroup,
            "location": location
        };

    
        new VisitModalService(payload).addVisit()
            .then((res: any) => {
                setOpenVisitModal(false);
                setOpenSpecialityModal(true);
                setChatSessionData({visit_id: res?.visitId});
            })
            .catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err.message,
                        success: false
                    })
                );
            });
    };

    const customWeightElement = () => {
        return (<InputAdornment position="end">KG</InputAdornment>);
    };

    const customHeightElement = () => {
        return (<InputAdornment position="end">CM</InputAdornment>);
    };


    const customAgeElement = () => {
        return (<InputAdornment position="end">Year</InputAdornment>);
    };

    const handleReason = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setReason(value);
        if (value.trim() !== "") {
            setReasonValidation(false);
        }
    };

    const handleLocation = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocation(e.target.value);
    };

    const handleGender = (e: any) => {
        setGender(e.target.value);
    };

    const handleCloseEditModal = () => {
        setOpenVisitModal(false);
        setReason("");
        setBloodGroup("");
        setGender("");
        setLocation("");
        setAge(null);
        setWeight(null);
        setHeight(null);

    };

    return (
        <><DialogModal open={openVisitModal}>
            <Box sx={{ p: 2, mx: 2 }}>
                <Grid container spacing={2} mt={1}>
                    <Grid size={{ xs: 6, md: 6, lg: 6 }} mt={1}>
                        <Typography className="wpx_font_style">{t("user_edit.reason_for_visit")}</Typography>
                    </Grid>
                    <Grid size={{ xs: 6, md: 6, lg: 6 }}>
                        <IconButton onClick={handleCloseEditModal} sx={{ color: "black", float: "right" }}>
                            <ClearOutlinedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container>
                    <TextField
                        type="string"
                        value={reason}
                        onChange={handleReason}
                        placeholder={t("user_edit.reason_for_visit")}
                        // multiline
                        className="wpx_input_style"
                        fullWidth={true} 
                    />       
                    { reasonValidation ?
                        <Typography color="error" sx={{ mt: 2 }}>{t("user_edit.reason")}</Typography>: null
                    }

                </Grid>

                <Grid container spacing={2} mt={3}>
                    <Typography className="wpx_font_style">{t("user_edit.context")}</Typography>
                </Grid>
                <Grid container spacing={1}>
                    <InputField
                        type="number"
                        value={weight !== null ? weight : ""}
                        placeHolder={t("user_edit.weight")}
                        onChange={handleWeight}
                        customIcon={true}
                        customIconElement={customWeightElement} 
                    />
                    <InputField
                        type="number"
                        value={height !== null ? height : ""}
                        placeHolder={t("user_edit.height")}
                        onChange={handleHeight}
                        customIcon={true}
                        customIconElement={customHeightElement} 
                    />
                    <InputField
                        type="number"
                        value={age !== null ? age : ""}
                        placeHolder={t("user_edit.age")}
                        onChange={(e) => handleAge(e)}
                        customIcon={true}
                        customIconElement={customAgeElement} 
                    />
                    <Select
                        value={bloodGroup}
                        options={bloodGroupArr}
                        placeholder={t("user_edit.blood_group")}
                        onChange={(e) => handleBloodGroup(e)}
                        fullWidth={true} 
                    />
                    <Select
                        value={gender}
                        options={genderArr}
                        placeholder={t("user_edit.gender")}
                        onChange={(e) => handleGender(e)}
                        fullWidth={true} 
                    />
                    <TextField
                        type="string"
                        value={location}
                        onChange={handleLocation}
                        placeholder={t("user_edit.location")}
                        className="wpx_text1_style"
                        fullWidth={true} 
                    />

                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Button
                        className="wpx_save__btn"
                        label={t("common.save")}
                        variant="contained"
                        onClick={handleNext} 
                    />
                </Grid>
            </Box>
        </DialogModal>
        <ChatSession
            startConversation={startConversation}
            setStartConversation={setStartConversation}
            specialityList={specialityList}
            userRole={userRole}
            isChatScreenOpen={isChatScreenOpen}
            setIsChatScreenOpen={setIsChatScreenOpen} />
        </>
    );
};


export default VisitModal;