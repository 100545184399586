import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { serverAPIPath } from "../config/apiPath";

class RepresentativeService {

    private axios;
    private payload;
    private headers;
    private baseURL;

    constructor(payload = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.axios = new Axios();
    }

    // Get list for all customer support representative in an organization
    async representativeList(orgId: string) {
        const response = await this.axios.get(`${this.baseURL}${serverAPIPath.CSR_LIST}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    // Add new customer support representative in an organization
    async addRepresentative() {
        const response = await this.axios.post(`${this.baseURL}${serverAPIPath.ADD_CSR}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    // Update a customer support representative in an organization
    async updateRepresentatve(orgId: string) {
        const response = await this.axios.put(`${this.baseURL}${serverAPIPath.EDIT_CSR}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    // Delete a customer support representative in an organization
    async deleteRepresentative(orgId: string) {
        const response = await this.axios.delete(`${this.baseURL}${serverAPIPath.DELETE_CSR}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
};

export default RepresentativeService;