import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { serverAPIPath } from "../config/apiPath";

class MedProfService {

    private axios;
    private payload;
    private headers;
    private baseURL;

    constructor(payload = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.axios = new Axios();
    }

    // Get list for all medical professional in an organization
    async medProfList(orgId: string) {
        const response = await this.axios.get(`${this.baseURL}${serverAPIPath.MP_LIST}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    // Add new medical profesional in an organization
    async addMedProf() {
        const response = await this.axios.post(`${this.baseURL}${serverAPIPath.ADD_MP}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    // Update a medical profesional in an organization
    async updateMedProf(orgId: string) {
        const response = await this.axios.put(`${this.baseURL}${serverAPIPath.EDIT_MP}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
    
    // Delete a medical profesional in an organization
    async deleteMedProf(orgId: string) {
        const response = await this.axios.delete(`${this.baseURL}${serverAPIPath.DELETE_MP}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
};

export default MedProfService;