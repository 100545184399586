import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, SelectChangeEvent, FormControl, FormControlLabel, RadioGroup, Radio, DialogActions, DialogContent, Stack, DialogContentText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InputField from "../../../components/common/input/InputField";
import Select from "../../../components/common/input/Select";
import Button from "../../../components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import DialogModal from "../../../components/common/modal/DialogModal";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { REGEX } from "../../../constants/regex";
import UserVerificationService from "../services/userVerificationService";
import { useAppDispatch } from "../../../store/hooks";
import { commonActions } from "../../../store/features/commonSlice";

const UserRegistration = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const from = location.state?.from;
    const editData = location.state?.editData || {};

    const [aadhaar, setAadhaar] = useState<string>(editData?.aadhaar || "");
    const [name, setName] = useState<string>(editData?.name || "");
    const [mobile, setMobile] = useState<string>(editData?.mobile || "");
    const [gender, setGender] = useState<string>(editData?.gender || "");
    const [dob, setDob] = useState<string>(editData?.dob || "");
    const [email, setEmail] = useState<string>(editData?.email || "");
    const [agentRelation, setAgentRelation] = useState<string>(editData?.agentRelation || "");
    const [agentDob, setAgentDob] = useState<string>("");
    const [agentMobile, setAgentMobile] = useState<string>("");
    const [agentAadhaar, setAgentAadhar] = useState<string>("");
    const [agentEmail, setAgentEmail] = useState<string>("");
    const [showAadhaarValidation, setShowAadhaarValidation] = useState<boolean>(false);
    const [showNameValidation, setShowNameValidation] = useState<boolean>(false);
    const [showMobileValidation, setShowMobileValidation] = useState<boolean>(false);
    const [showAgentRelationValidation, setShowAgentRelationValidation] = useState<boolean>(false);
    const [showDobValidation, setShowDobValidation] = useState<boolean>(false);
    const [showGenderValidation, setShowGenderValidation] = useState<boolean>(false);
    const [selectedRadioOption, setSelectedRadioOption] = useState<string>(editData?.aadhaar  ? "withAadhaar" : "withoutAadhaar" );
    const [agentId, setAgentId] = useState<any>(editData.agentId || "");
    const [isAgentIdModalOpen, setIsAgentModalOpen] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const [agentIdFound, setAgentIdFound] = useState<boolean>(false);
    const [showEmailValidation, setShowEmailValidation] = useState<boolean>(false);
    const [aadhaarRegistration] = useState<boolean>(editData?.aadhaar ? true : false);

    const genderArr = [
        { value: "M", label: "Male" },
        { value: "F", label: "Female" },
        { value: "O", label: "Others" },
    ];

    const agentRelationArray = [
        { value: "guardian", label: "Guardian" },
        { value: "representative", label: "Representative" },
        { value: "spouse", label: "Spouse" },
        { value: "-", label: "Other" },
    ];

    const handleChange = (event: SelectChangeEvent<string>) => {
        setGender(event.target.value);
        setShowGenderValidation(true);
        if (event.target.value){
            setShowGenderValidation(false);
        }
    };

    const handleAgentRelationChange = (event: SelectChangeEvent<string>) => {
        setAgentRelation(event.target.value);
        setShowAgentRelationValidation(true);
        if (event.target.value){
            setShowAgentRelationValidation(false);
        }
    };

    const handleDob = (event: SelectChangeEvent<string>) => {
        setDob(event.target.value);
        setShowDobValidation(true);
        if (event.target.value){
            setShowDobValidation(false);
        }
    };

    const handleAgentDob = (event: SelectChangeEvent<string>) => {
        setAgentDob(event.target.value);
    };

    const handleNext = () => {
        let payload = {};
        if ( selectedRadioOption === "withAadhaar") {
            payload = {
                aadhaarRegistration: true,
                aadhaar: aadhaar,
                dob: dob,
                gender:gender,
                name:name,
                email:email,
                phone:"+91" + mobile
            };
        } else {
            payload = {
                aadhaarRegistration: false,
                dob: dob,
                gender:gender,
                name:name,
                email:email,
                phone:"+91" + mobile,
                agentRelation:agentRelation,
                agentId:agentId

            };
        }
        new UserVerificationService(payload).create()
            .then((res) => {
                navigate(ROUTES.CSR.PATEINT_CHAT_HISTORY);
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: res?.message,
                    })
                );
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err?.message,
                        success: false
                    })
                );
            });
    };

    const handleInput = (inputVal: React.ChangeEvent<HTMLInputElement>) => {
        if (inputVal.target.name === "aadhaarNumber") {
            const pattern = /^\d{0,12}$/;
            if (pattern.test(inputVal.target.value)) {
                setAadhaar(inputVal.target.value); 
                setShowAadhaarValidation(true);
             
                if(inputVal?.target?.value?.length > 11){
                    setShowAadhaarValidation(false);
                }
            }
        } else if (inputVal.target.name === "fullName") {
            const maxLength = 40;
           
            if (inputVal.target.value.length <= maxLength) {
                setName(inputVal.target.value);
                setShowNameValidation(true); 
            } else {
                setShowNameValidation(false);
            }
    
            // setShowAadhaarValidation(false);
        } else if (inputVal.target.name === "mobile") {
            const pattern = /^\d{0,10}$/;
            if (pattern.test(inputVal.target.value)) {
                setMobile(inputVal.target.value);
                setShowMobileValidation(true);

                if(inputVal?.target?.value?.length > 9){
                    setShowMobileValidation(false);
                }
            }
        } else if (inputVal.target.name === "email") {
            setEmail(inputVal.target.value);
            if (REGEX.EMAIL_VALIDATION.test(inputVal.target.value)) {
                setShowEmailValidation(false);
            }else {
                setShowEmailValidation(true);
            }
        }
    };
    const isFormComplete = aadhaar.length === 12 && name?.trim() && mobile.length === 10 && gender && dob && !showEmailValidation && email;

    const isWithoutAadharFormComplete = name?.trim() && mobile.length === 10 && gender && dob && !showEmailValidation && email && agentId && agentRelation?.trim();

    const handleRadioSelection = (e:any) => {
        setSelectedRadioOption(e.target.value);
    };

    const handleAgent = ( ) => {
        setIsAgentModalOpen(true);        
    };

    const handleAgentIdModalClose = () => {
        setIsAgentModalOpen(false);
    };

    const handleSaveAgentId = ( ) => {
        if (validate()) {
            let payload = {};
            if (agentAadhaar) {
                payload = {
                    aadhaarverification: true,
                    aadhaar: agentAadhaar,
                    dob: agentDob,
                    isAgent: true
                };
            } else {
                payload = {
                    aadhaarverification: false,
                    email: agentEmail,
                    dob: agentDob,
                    phone: agentMobile,
                    isAgent: true

                };
            }
            new UserVerificationService(payload).verify()
                .then((res) => {
                    if(res){
                        setAgentId(res?.agentId);
                        setIsAgentModalOpen(false);  
                        setAgentIdFound(false);
                    }else{
                        setAgentIdFound(true);
                    }                  
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err?.message,
                            success: false
                        })
                    );
                });
        }
    };

    const validate = () => {
        const newErrors: any = {};
    
        if (!agentDob) {
            newErrors.dob = "Date of Birth is required.";
        }
    
        if (!agentAadhaar && !agentEmail && !agentMobile) {
            newErrors.general = "At least one of Aadhaar, Email, or Mobile Number is required.";
        }
    
        if (agentAadhaar && !/^\d{12}$/.test(agentAadhaar)) {
            newErrors.aadhaar = "Aadhaar Number must be 12 digits.";
        }
    
        if (agentEmail && !REGEX.EMAIL_VALIDATION.test(agentEmail)) {
            newErrors.email = "Invalid email format.";
        }
    
        if (agentMobile && !/^\d{10}$/.test(agentMobile)) {
            newErrors.mobile = "Mobile Number must be 10 digits.";
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAgentAadhar = (e:any) => {
        const pattern = /^\d{0,12}$/;
        if (pattern.test(e.target.value)) {
            setAgentAadhar(e.target.value); 
        }
    };

    const handleAgentMobile = (e:any) => {
        const pattern = /^\d{0,10}$/;
        if (pattern.test(e.target.value)) {
            setAgentMobile(e.target.value);
        }
    };

    return (
        <>
            <Box className="wpx_registration_container">
                <Typography variant="h5">{t("new_user.basic_details")}</Typography>
                <Typography>{t("new_user.private_informations")}</Typography>
                {
                    from === "edit" ? null :
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={selectedRadioOption}
                                onChange={handleRadioSelection}
                            >
                                <FormControlLabel value="withAadhaar" control={<Radio />} label={t("new_user.register_with_aadhaar")} />
                                <FormControlLabel value="withoutAadhaar" disabled= {aadhaarRegistration && from !== "edit"} control={<Radio />} label={t("new_user.register_without_aadhaar")}  />
                            </RadioGroup>
                        </FormControl>                     
                }           
                {
                    selectedRadioOption === "withAadhaar" ? 
                        <Box component="form" mt={2}>
                            <Grid container spacing={2} mt={2}>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField
                                        value={aadhaar}
                                        placeHolder={t("new_user.aadhaar")}
                                        name="aadhaarNumber"
                                        onChange={(e) => handleInput(e)}
                                        disabled = {from !== "edit" && aadhaarRegistration}
                                    />
                                    {
                                        showAadhaarValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.aadhaar_number")}
                                            </Typography>: null
                                    }
                                </Grid>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField
                                        value={name}
                                        placeHolder={t("new_user.name")}
                                        name="fullName"
                                        onChange={(e) => handleInput(e)}
                                    />
                                    {
                                        showNameValidation && !name?.trim() ? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.fill_name")}
                                            </Typography>: null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={2}>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField 
                                        value={mobile} 
                                        placeHolder={t("new_user.mobile")}
                                        name="mobile" 
                                        onChange={(e) => handleInput(e)}
                                    />
                                    {
                                        showMobileValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.mobile_number")}
                                            </Typography>: null
                                    }
                                </Grid>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField
                                        type="text"
                                        value={dob}
                                        name="dob"
                                        placeHolder={t("new_user.date_of_birth")}
                                        onFocus={(e: any) => {
                                            e.target.type = "date";
                                            e.target.max = new Date().toISOString().split("T")[0]; 
                                        }}
                                        onBlur={(e: any) => {if (!e.target.value) e.target.type = "text";}}
                                        onKeyDown={(e: any) => e.preventDefault()}
                                        onChange={(e) => handleDob(e)}
                                    />
                                    {
                                        showDobValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.dob")}
                                            </Typography>: null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={2}>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <Select
                                        value={gender}
                                        options={genderArr}
                                        onChange={handleChange}
                                        placeholder={t("new_user.gender")}
                                        formClassName="wpx_gender_width"
                                    />   
                                    {
                                        showGenderValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.select_gender")}
                                            </Typography>: null
                                    }                
                                </Grid>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField 
                                        value={email}
                                        variant="outlined"
                                        placeHolder={t("new_user.enter_email")} 
                                        name ="email"
                                        onChange={(e) => handleInput(e)}
                                    />
                                    {
                                        showEmailValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.enter_valid_email")}
                                            </Typography>: null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={2}> 
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <Button
                                        className={`wpx_verification__wrapper__disabledBtn ${isFormComplete ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                        label={t("common.next")}
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={!isFormComplete}
                                    />                    
                                </Grid>
                            </Grid>
                        </Box> 
                        :
                        null
                }

                {
                    selectedRadioOption === "withoutAadhaar" ? 
                        <Box component="form" mt={2}>
                            <Grid container spacing={2} mt={2}>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField
                                        value={agentId}
                                        placeHolder={t("new_user.agent_id")}
                                        name="agentId"
                                        onChange={() => handleAgent()}
                                    />
                                </Grid>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <Select
                                        value={agentRelation}
                                        options={agentRelationArray}
                                        onChange={handleAgentRelationChange}
                                        placeholder={t("new_user.agent_relation")}
                                        formClassName="wpx_gender_width"
                                    />
                                    {
                                        showAgentRelationValidation ? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.fill_agent_relation")}
                                            </Typography>: null
                                    }
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} mt={2}>

                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField
                                        value={name}
                                        placeHolder={t("new_user.name")}
                                        name="fullName"
                                        onChange={(e) => handleInput(e)}
                                    />
                                    {
                                        showNameValidation && !name?.trim() ? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.fill_name")}
                                            </Typography>: null
                                    }
                                </Grid>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField 
                                        value={mobile} 
                                        placeHolder={t("new_user.mobile")}
                                        name="mobile" 
                                        onChange={(e) => handleInput(e)}
                                        disabled = {from !== "edit" && !aadhaarRegistration}
                                    />
                                    {
                                        showMobileValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.mobile_number")}
                                            </Typography>: null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={2}>

                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField
                                        type="text"
                                        value={dob}
                                        name="dob"
                                        placeHolder={t("new_user.date_of_birth")}
                                        onFocus={(e: any) => {
                                            e.target.type = "date";
                                            e.target.max = new Date().toISOString().split("T")[0]; 
                                        }}
                                        onBlur={(e: any) => {if (!e.target.value) e.target.type = "text";}}
                                        onKeyDown={(e: any) => e.preventDefault()}
                                        onChange={(e) => handleDob(e)}
                                    />
                                    {
                                        showDobValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {("new_user.dob")}
                                            </Typography>: null
                                    }
                                </Grid>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <Select
                                        value={gender}
                                        options={genderArr}
                                        onChange={handleChange}
                                        placeholder={t("new_user.gender")}
                                        formClassName="wpx_gender_width"
                                    />   
                                    {
                                        showGenderValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.select_gender")}
                                            </Typography>: null
                                    }                
                                </Grid>                             
                            </Grid>
                            <Grid container spacing={2} mt={2}>
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <InputField 
                                        value={email}
                                        variant="outlined"
                                        placeHolder={t("new_user.enter_email")} 
                                        name ="email"
                                        onChange={(e) => handleInput(e)}
                                    />
                                    {
                                        showEmailValidation? 
                                            <Typography color="error" sx={{ ml: 2, mt:1 }}>
                                                {t("new_user.enter_valid_email")}
                                            </Typography>: null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={2}> 
                                <Grid size={{ xs: 10, sm: 5, md: 5, lg: 4 }}>
                                    <Button
                                        className={`wpx_verification__wrapper__disabledBtn ${isWithoutAadharFormComplete ? "wpx_verification__wrapper__disabledBtn__bgChange" : ""}`}
                                        label={t("common.next")}
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={!isWithoutAadharFormComplete}
                                    />                    
                                </Grid>
                            </Grid>
                        </Box> 
                        :
                        null
                }            
            </Box>

            <DialogModal open={isAgentIdModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"31px", justifyContent: "space-between"  }}
                > 
                    <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>
                        {t("new_user.search_agent")}
                    </Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleAgentIdModalClose}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    sx={{ width: "500px" }}
                                >
                                    <InputField
                                        value={agentAadhaar}
                                        placeHolder={t("new_user.aadhaar")}
                                        name="aadhaarNumber"
                                        onChange={(e) => handleAgentAadhar(e)}
                                    />
                                    {errors.aadhaar && (
                                        <Typography variant="body2" color="error">
                                            {errors.aadhaar}
                                        </Typography>
                                    )}
                                    <InputField 
                                        value={agentEmail}
                                        variant="outlined"
                                        placeHolder={t("new_user.enter_email")}
                                        onChange={(e)=>setAgentEmail(e.target.value)}
                                    />
                                    {errors?.email && (
                                        <Typography variant="body2" color="error">
                                            {errors?.email}
                                        </Typography>
                                    )}

                                    <InputField 
                                        value={agentMobile} 
                                        placeHolder={t("new_user.mobile")}
                                        name="mobile" 
                                        onChange={(e) => handleAgentMobile(e)}
                                    />
                                    {errors?.mobile && (
                                        <Typography variant="body2" color="error">
                                            {errors?.mobile}
                                        </Typography>
                                    )}
                                    <InputField
                                        type="text"
                                        value={agentDob}
                                        name="dob"
                                        placeHolder={t("new_user.date_of_birth")}
                                        onFocus={(e: any) => {
                                            e.target.type = "date";
                                            e.target.max = new Date().toISOString().split("T")[0]; 
                                        }}
                                        onBlur={(e: any) => {if (!e.target.value) e.target.type = "text";}}
                                        onKeyDown={(e: any) => e.preventDefault()}
                                        onChange={(e) => handleAgentDob(e)}
                                    />  
                                    {errors.dob && (
                                        <Typography variant="body2" color="error">
                                            {errors.dob}
                                        </Typography>
                                    )}     
                                </Stack>
                                {errors.general && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        className="wpx_marginTop"
                                        style={{  marginTop: "10%" }}
                                    >
                                        {errors.general}
                                    </Typography>
                                )}
                                {agentIdFound && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        className="wpx_marginTop"
                                        style={{ textAlign:"center", marginTop: "10%" }}
                                    >
                                        {t("new_user.agent_id_not_found")}
                                    </Typography>
                                )}
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        label="Search"
                                        onClick={handleSaveAgentId}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>
        </>
    );
};

export default UserRegistration;
