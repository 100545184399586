import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { serverAPIPath } from "../config/apiPath";

class OrgAdminService {

    private axios;
    private payload;
    private headers;
    private baseURL;

    constructor(payload = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.axios = new Axios();
    }

    async orgAdminList(orgId : any) {
        const response = await this.axios.get(`${this.baseURL}${serverAPIPath.ORG_ADMIN_LIST}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    async addOrgAdmin() {
        const response = await this.axios.post(`${this.baseURL}${serverAPIPath.ADD_ORG_ADMIN}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    async orgAdminUpdate(orgId : any) {
        const response = await this.axios.put(`${this.baseURL}${serverAPIPath.ORG_ADMIN_LIST}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    async deleteOrgAdmin(orgId : any) {
        const response = await this.axios.delete(`${this.baseURL}${serverAPIPath.ORG_ADMIN_LIST}/${orgId}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };


}

export default OrgAdminService;