import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { jsonToQueryParams } from "../../../utils/stringUtils";
import { serverAPIPath } from "../config/apiPath";

class RefreshLoginService {

    private axios;
    private payload;
    private headers;
    private baseURL;

    private queryParams;

    constructor(payload = {}, queryParams = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.queryParams = jsonToQueryParams(queryParams);
        this.axios = new Axios();
    }

    async refreshLogin() {
        let API_URL = `${this.baseURL}${serverAPIPath.REFRESH_LOGIN}` ;
        if(this.queryParams !== "" ) {
            API_URL = `${API_URL}?${this.queryParams}`;
        }
        const response = await this.axios.post(API_URL, this.payload, this.headers);
        if(response?.status === 200) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
}

export default RefreshLoginService;