export const mockAPIPath = {
    ACTIVE_SESSION_LIST: "/json-schemas/ActiveSessionList.json",
    START_TASK: "/json-schemas/ActiveSessionList.json",
    USER_CHAT_HISTORY_LIST: "/json-schemas/ChatHistoryList.json",
    SPECIALITY_LIST: "/json-schemas/SpecialityList.json",
    REFER_SPECIALIST: "/json-schemas/ReferSpecialistList.json",
    COMPLETE_TASK: "/json-schemas/CommonPostResponse.json",
    USER_CONSULTATION_LIST: "/json-schemas/UserConsultaionList.json"
};

export const serverAPIPath = {
    ACTIVE_SESSION_LIST: "/task/active",
    START_TASK: "/task/start",
    COMPLETE_TASK: "/consultations/complete",
    USER_CONSULTATION_LIST: "/consultations/CKANSW/VASI"
};
