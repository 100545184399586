import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../constants/constants";
import Table from "../../../components/common/table/Table";
import DialogModal from "../../../components/common/modal/DialogModal";
import {
    Box,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InputField from "../../../components/common/input/InputField";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AddIcon from "@mui/icons-material/Add";
import Skeleton from "../../../components/common/skeleton";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { REGEX } from "../../../constants/regex";
import OrgAdminService from "../services/orgAdminService";

const ManageOrgAdminList = () => {
    const [t] = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { state } = location ;

    const [orgAdminList, setOrgAdminList] = useState<any>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [orgAdminData, setOrgAdminData] = useState<any>(null);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [emailVaildation, setEmailValidation] = useState<boolean>(false);
    const [refreshList, setRefreshList] = useState<boolean>(false);

    useEffect(() => {
        const organizationAdminList = () => {
            setRefreshList(false);
            const payload = {};
            new OrgAdminService(payload).orgAdminList(state.orgId)
                .then((res) => {
                    setOrgAdminList(res);
                    setSkeleton(false);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err.message,
                            success: false
                        })
                    );          
                });
        };

        organizationAdminList();
    }, [refreshList, dispatch, state.orgId]);

    const tableData =
    orgAdminList?.map((item:any,index:any) => ({
        id: (index + 1),
        adminName: item?.name ,
        adminEmail: item?.credentials?.email,
        member_id: item?.member_id
    })) ?? [];
       
    const columns = [
        { id: "id", label: t("common.s_no"), width: "20%"  },
        { id: "adminName", label: t("manage_org_admin.admin_name"), width: "30%"  },
        { id: "adminEmail", label: t("manage_org_admin.admin_email"), width: "30%"  },
        { id: "actions", label: t("common.action"), width: "20%"  },
    ];

    const handleEdit = (user: any) => {
        setIsEditModalOpen(true);
        setOrgAdminData(user);
        setShowValidation(false);
    };

    // const handleNavigate = (user: any) => {
    //     window.open(ROUTES.OA.MANAGE_MP);
    // };

    const handleDelete = (user: any) => {
        setIsDeleteModalOpen(true);
        setOrgAdminData(user);
    };

    const handleFieldChange = (e:any, fieldName: string) => {
        if(fieldName === "adminEmail"){
            if (REGEX.EMAIL_VALIDATION.test(e.target.value)) {
                setEmailValidation(false);
            }
        }
        const tempData ={...orgAdminData};
        tempData[fieldName] = e.target.value;
        setOrgAdminData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setIsAddModalOpen(true);
        setOrgAdminData(null);
    };

    const handleAddOrgAdmin = () => {
        setShowValidation(true);
        if (!CONSTANTS.EMAIL_REGEX.test(orgAdminData?.adminEmail) && orgAdminData?.adminEmail) {
            setEmailValidation(true);
        }else {
            setEmailValidation(false);
            if(orgAdminData?.adminName.trim() && orgAdminData?.adminEmail.trim()){
                const payload = {
                    "id": state.orgId,
                    "name": orgAdminData?.adminName.trim(),
                    "email": orgAdminData?.adminEmail.trim()
                };
                new OrgAdminService(payload).addOrgAdmin()
                    .then((res) => {
                        setRefreshList(true);
                        dispatch(
                            commonActions.SHOW_SNACKBAR({
                                show: true,
                                message: res.message
                            })
                        );
                        setIsAddModalOpen(false);
                    }).catch((err) => {
                        dispatch(
                            commonActions.SHOW_SNACKBAR({
                                show: true,
                                message: err.message,
                                success: false
                            })
                        );
                        setIsAddModalOpen(false);
                    });
            }  
        }  
    };

    const handleEditOrgAdmin = () => {
        setShowValidation(true);
        if(orgAdminData?.adminName?.trim() && orgAdminData?.adminEmail){
            const payload = {
                "name": orgAdminData?.adminName.trim(),
                "memberId": orgAdminData?.member_id
            };
            new OrgAdminService(payload).orgAdminUpdate(state.orgId)
                .then((res) => {
                    setRefreshList(true);
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: res.message
                        })
                    );
                    setIsEditModalOpen(false);  
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err.message,
                            success: false
                        })
                    );
                    setIsEditModalOpen(false);  
                });
        }    
    };

    const handleOrgAdminDelete = () => {
        const payload = {
            "memberId": orgAdminData?.member_id
        };
        new OrgAdminService(payload).deleteOrgAdmin(state.orgId)
            .then((res) => {
                setRefreshList(true);
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: res.message
                    })
                );
                setIsDeleteModalOpen(false);
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err.message,
                        success: false
                    })
                );
                setIsDeleteModalOpen(false);
            });
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
    };

    const closeAddDataModal = () => {
        setIsAddModalOpen(false);
    };

    const handleBack = () => {
        navigate(ROUTES.SA.MANAGE_ORG);
    };

    return (
        <Container>
            <Grid container spacing={2} sx ={{my :2}}>
                <Grid size={10}>
                    <Typography variant="caption">{t("manage_org_admin.current_organization")}</Typography>
                    <Typography variant="subtitle2">{state.orgName}</Typography>     
                </Grid>
                <Grid size={2} sx = {{display: "flex", justifyContent: "end", alignItems: "end"}}>
                    <Button
                        variant="contained"
                        label="Back"
                        onClick={handleBack}
                    />
                </Grid>              
            </Grid>
            {skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    count={15}
                />
            ) : (
                <Table
                    data={tableData}
                    columns={columns}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    // onNavigate={handleNavigate}
                />
            // TODO: Pagination need to be added in future                  
            )}  
            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={handleAddBtnClick}
                    startIcon={<AddIcon />}
                />
            </Box>
            <DialogModal open={isDeleteModalOpen}>
                <Box sx={{ py: 2 }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.delete_confirmation_msg")}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.msg_to_proceed")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleOrgAdminDelete}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleDeleteModalClose}
                        />
                    </Box>
                </Box>
            </DialogModal>
            <DialogModal open={isEditModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"8px", justifyContent: "space-between"  }}
                >        
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}} >{t("manage_org_admin.edit_org_admin")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField value={orgAdminData?.adminName} onChange = {(e)=>{handleFieldChange(e, "adminName");}} variant="outlined" label="Admin Name" placeHolder="Enter admin name" />
                                {
                                    showValidation && !orgAdminData?.adminName?.trim() ? 
                                        <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                        : null
                                }
                                {/* <InputField value={orgAdminData?.adminEmail} onChange = {(e)=>{handleFieldChange(e, "adminEmail");}} disabled={true}  variant="outlined" label="Email" placeHolder="Enter email"/>
                                {
                                    showValidation && !orgAdminData?.adminEmail ? 
                                        <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                        : null
                                } */}
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}   
                                    onClick={handleEditOrgAdmin}                                                               
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>
            <DialogModal open={isAddModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml:"31px", justifyContent: "space-between"  }}
                >
                    <Typography sx={{fontWeight: "500", fontSize:"18px"}}>{t("manage_org_admin.add_org_admin")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={closeAddDataModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={orgAdminData?.adminName ? orgAdminData?.adminName : ""} onChange = {(e)=>{handleFieldChange(e, "adminName");}}  variant="outlined" label="Admin Name" placeHolder="Enter admin name"/>
                                    {
                                        showValidation && !orgAdminData?.adminName?.trim() ? 
                                            <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                            : null
                                    }
                                    <InputField value={orgAdminData?.adminEmail ? orgAdminData?.adminEmail : ""} onChange = {(e)=>{handleFieldChange(e, "adminEmail");}} variant="outlined" label="Email" placeHolder="Enter email" />
                                    {
                                        showValidation && !orgAdminData?.adminEmail ? 
                                            <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                            : null
                                    }
                                    {
                                        emailVaildation && orgAdminData?.adminEmail ? <Typography color="error">{t("common.valid_email")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddOrgAdmin}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>
        </Container>
    );
};

export default ManageOrgAdminList;
