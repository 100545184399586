import React, { JSXElementConstructor } from "react";
import Snackbar from "@mui/material/Snackbar";
import { TransitionProps } from "@mui/material/transitions";
import { SlideProps, Slide } from "@mui/material";

type DataProps = {
    open: boolean;
    onClose?: () => void;
    message: string;
    duration: number;
    success?: boolean;
    TransitionComponent?: JSXElementConstructor<TransitionProps> | undefined;
};
/**
 * 
 * @param props : Required DataProps
 * @returns - Common Snackbar
 */
const SimpleSnackbar = (props: DataProps) => {

    const { open, message, duration = 3000, onClose, success = true } = props;

    function SlideTransition(props: SlideProps) {
        return <Slide {...props} direction="left" />;
    }

    return (

        <>
            <Snackbar
                className={`${success? "wpx_snackbar" : "wpx_snackbar_style"}`}
                open={open}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={duration}
                onClose={onClose}
                message={message}
                TransitionComponent={SlideTransition}
            />
        </>
    );
};

export default SimpleSnackbar;