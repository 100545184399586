import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class UserVerificationService {

    private axios;
    private payload;
    private headers;
    private baseURL;
    private dummyCheck;
    private localURL;

    constructor(payload = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.axios = new Axios();
        this.dummyCheck = API.DUMMY_CHECK;
        this.localURL = API.APP_URL;
    }
    async verify() {
        const response = this.dummyCheck === "true" ? 
            await this.axios.get(this.localURL + mockAPIPath.VERIFY_USER, this.payload, this.headers):
            await this.axios.post(`${this.baseURL}${serverAPIPath.VERIFY_USER}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    async create() {
        const response = this.dummyCheck === "true" ? 
            await this.axios.get(this.localURL + mockAPIPath.CREATE_USER, this.payload, this.headers):
            await this.axios.post(`${this.baseURL}${serverAPIPath.CREATE_USER}`, this.payload, this.headers);      
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
};

export default UserVerificationService;