import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./styles/main.scss";
import { ROUTES } from "./constants/routes";
import ManageOrgAdminList from "./modules/SA/pages/OrgAdmin";
import ManageOrganizationList from "./modules/SA/pages/Organization";
import Login from "./modules/auth/login";
import OrganizationSelection from "./modules/common/organizationSelection";
import ManageMedProfList from "./modules/OA/pages/MedProf";
import ChooseSpeciality from "./modules/CSR/pages/SelectSpeciality";
import UserRegistration from "./modules/CSR/pages/UserRegistration";
import ActiveSessionList from "./modules/MP/pages/ActiveSession";
import UserChatHistoryList from "./modules/MP/pages/UserChatHistory";
import ChatHistory from "./modules/CSR/pages/ChatHistory";
import ReferSpecialist from "./modules/MP/pages/ReferSpecialist";
import IncorrectRoute from "./components/common/error/404";
import ManageCSRList from "./modules/OA/pages/CSR";
import UserVerification from "./modules/common/UserVerification";
import Layout from "./Layout";
import UserConsultaionList from "./modules/MP/pages/UserConsultationList";

function App() {

    const router = createBrowserRouter([
        {
            path: ROUTES.ROOT,
            element: <Layout />,
            children: [
                { path: ROUTES.ROOT, element: <Login /> },
                {
                    path: ROUTES.SA.BASE,
                    children: [
                        { path: ROUTES.SA.MANAGE_ORG, element: <ManageOrganizationList /> },
                        { path: ROUTES.SA.MANAGE_OA, element: <ManageOrgAdminList /> }
                    ]
                },
                {
                    path: ROUTES.OA.BASE,
                    children: [
                        { path: ROUTES.OA.MANAGE_MP, element: <ManageMedProfList /> },
                        { path: ROUTES.OA.MANAGE_CSR, element: <ManageCSRList /> }
                    ]
                },
                {
                    path: ROUTES.MP.BASE,
                    children: [
                        { path: ROUTES.MP.REFER_SPECIALIST, element: <ReferSpecialist /> },
                        { path: ROUTES.MP.ACTIVE_SESSION_LIST, element: <ActiveSessionList /> },
                        { path: ROUTES.MP.USER_CHAT_HISTORY, element: <UserChatHistoryList /> },
                        { path: ROUTES.MP.USER_CONSULTATION_LIST, element: <UserConsultaionList/> }
                    ]
                },
                {
                    path: ROUTES.CSR.BASE,
                    children: [
                        { path: ROUTES.CSR.SELECT_SPECIALITY, element: <ChooseSpeciality /> },
                        { path: ROUTES.CSR.USER_REGISTRATION, element: <UserRegistration /> },
                        { path: ROUTES.CSR.PATEINT_CHAT_HISTORY, element: <ChatHistory /> },
                    ]
                },
                {
                    path: `/:userRoute${ROUTES.COMMON.BASE}`,
                    children: [
                        { path: `/:userRoute${ROUTES.COMMON.USER_VERIFICATION}`, element: <UserVerification /> },
                        { path: `/:userRoute${ROUTES.COMMON.ORGANIZATION_SELECTION}`, element: <OrganizationSelection /> }
                    ]
                },
                { path: ROUTES.UNDEFINED, element: <IncorrectRoute /> }
            ]
        }
    ]);


    return (
        <RouterProvider router={router} />
    );
}

export default App;
