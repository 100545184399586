import React, { useEffect, useState } from "react";
import { mockAPIPath } from "../config/apiPath";
import useAxios from "../../../hooks/useAxios";
import { Box, Checkbox, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Button from "../../../components/common/button/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";
import { useTranslation } from "react-i18next";


/**
 * 
 * @returns Specialist list to choose
 */
const ReferSpecialist = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [referSpecialistList, setReferSpecialistList] = useState<any>([]);
    const [selectedMedProf, setSelectedMedProf] = useState<string>("");

    const referSpecialistListApiCall = useAxios({
        url: `${mockAPIPath.REFER_SPECIALIST}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = referSpecialistListApiCall;
        if (data) {
            setReferSpecialistList(data.data);
        }
    }, [referSpecialistListApiCall]);

    const handleClickForward = () => {
        dispatch(
            commonActions.SHOW_SNACKBAR({
                show: true,
                message: `Rahul Misra referred to ${selectedMedProf} successfully`
            })
        );
        navigate(ROUTES.MP.ACTIVE_SESSION_LIST);
    };

    function handleBackClick(): void {
        navigate(ROUTES.MP.USER_CHAT_HISTORY);
    }

    const handleMedProfCheckbox = (e:any, name:string ) => {
        if(selectedMedProf === name){
            setSelectedMedProf("");
        }else{
            setSelectedMedProf(name);
        }
    };

    return (
        <Container>
            <Box sx ={{p: 5}}>
                <Box>
                    <Typography variant="h5">{t("refer_specialist.choose_specialist")}</Typography>
                    <Typography>{t("refer_specialist.select_med_prof")}</Typography>
                </Box>
                <Box sx={{ minHeight: "68vh", overflow: "auto", marginBottom: "10px", marginTop: "10px" }}>
                    <Grid container spacing={2.5} mt={2} >
                        {referSpecialistList.map((item: any, index: any) => {
                            return (
                                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }} key={index}>
                                    <Box sx= {{position : "relative"}} onClick={(e)=>handleMedProfCheckbox(e, item.doctorName)}>
                                        <Box component="img" sx={{ width: "100%", "&:hover":{border: "1.5px solid #1c1c1c"}, cursor:"pointer" }} className="" src={item.img} alt={item.doctorName} />
                                        <Typography>{item.doctorName}</Typography>
                                        <Typography>{item.doctorSpeciality}</Typography>                               
                                        <Checkbox sx ={{position: "absolute", right: "0%", bottom: "20%",visibility:`${item.doctorName === selectedMedProf ? "visible": "hidden"}`, color: "#1c1c1c", "&.Mui-checked": {color: "#1c1c1c"}}} checked ={item.doctorName === selectedMedProf}  />
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
                <Grid>
                    <Button
                        className="wpx_forward_btn"
                        variant="contained"
                        label={t("refer_specialist.forward")}
                        onClick={handleClickForward}
                        disabled ={!selectedMedProf}
                    />
                    <Button
                        className="outlineBtn_reffered_mp"
                        variant="contained"
                        label={t("refer_specialist.report_list")}
                        onClick={handleBackClick}
                    />
                </Grid>
            </Box>
        </Container>
    );
};

export default ReferSpecialist;