import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { jsonToQueryParams } from "../../../utils/stringUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class MedicalProfessionalService {

    private axios;
    private payload;
    private headers;
    private baseURL;
    private localURL;
    private dummyCheck;
    private queryParams;

    constructor(payload = {}, queryParams = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.queryParams = jsonToQueryParams(queryParams);
        this.localURL = API.APP_URL;
        this.dummyCheck = API.DUMMY_CHECK;
        this.axios = new Axios();
    }

    async activeTaskList() {
        let API_URL = this.dummyCheck === "true" ? this.localURL + mockAPIPath.ACTIVE_SESSION_LIST : `${this.baseURL}${serverAPIPath.ACTIVE_SESSION_LIST}` ;
        if(this.queryParams !== "" ) {
            API_URL = `${API_URL}?${this.queryParams}`;
        }
        const response = await this.axios.get(API_URL, this.payload, this.headers);
        if(response?.status === 200) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };

    async startTask() {
        let API_URL = this.dummyCheck === "true" ? this.localURL + mockAPIPath.START_TASK : `${this.baseURL}${serverAPIPath.START_TASK}` ;
        const response = await this.axios.post(API_URL, this.payload, this.headers);
        if(response?.status === 200) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async completeTask() {
        let API_URL = this.dummyCheck === "true" ? this.localURL + mockAPIPath.COMPLETE_TASK : `${this.baseURL}${serverAPIPath.COMPLETE_TASK}` ;
        const response = await this.axios.post(API_URL, this.payload, this.headers);
        if(response?.status === 200) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

}

export default MedicalProfessionalService;