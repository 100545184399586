import { API } from "../../../../config/api.config";
import Axios from "../../../../utils/axios";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class OrganizationService {

    private axios;
    private payload;
    private headers;
    private baseURL;
    private localURL;
    private dummyCheck;

    constructor(payload = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.localURL = API.APP_URL;
        this.dummyCheck = API.DUMMY_CHECK;
        this.axios = new Axios();
    }

    async organizationList() {
        const API_URL = this.dummyCheck === "true" ? this.localURL + mockAPIPath.ORGANIZATION_LIST : this.baseURL + serverAPIPath.ORGANIZATION_LIST;
        const response = await this.axios.get(API_URL, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
};

export default OrganizationService;