import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import Table from "../../../components/common/table/Table";
import { Container, Grid2, IconButton, Typography } from "@mui/material";
import { mockAPIPath } from "../config/apiPath";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ChatSession from "../../../components/chatSession";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { specialityListType } from "../../../types/CommonType";
import Insight from "../../../components/chatSession/insight";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import Skeleton from "../../../components/common/skeleton";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ChatIcon from "@mui/icons-material/Chat";
import Pagination from "../../../components/common/table/Pagination";
import { CONSTANTS } from "../../../constants/constants";
import MedicalProfessionalService from "../services/medicalProfessionalService";
import { commonActions } from "../../../store/features/commonSlice";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const UserChatHistoryList = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    const userDetails = useAppSelector((state) => state.common.userDetails);
    const currentConsulatationDetails = useAppSelector((state) => state.common.currentConsultation);

    const [specialityList, setSpecialtyList] = useState<specialityListType>([]);
    const [chatHistoryList, setChatHistoryList] = useState<any>([]);
    const [selectedChatHistory, setSelectedChatHistory] = useState<any>(null);
    const [startConversation, setStartConversation] = useState<boolean>(false);
    const [isChatScreenOpen, setIsChatScreenOpen] = useState<boolean>(false);
    const userRole = useAppSelector((state) => state.common.userRole);
    const [isInsightOpen, setIsInsightOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);
    const [isChatStart, setIsChatStart] = useState<boolean>(false);


    const userChatHistoryApiCall = useAxios({
        url: `${mockAPIPath.USER_CHAT_HISTORY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = userChatHistoryApiCall;
        if (data) {
            setChatHistoryList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
        }
        setSkeleton(false);
    }, [userChatHistoryApiCall]);

    const { data: specialityListApiData } = useAxios({
        url: `${mockAPIPath.SPECIALITY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        setSpecialtyList(specialityListApiData?.data.filter(((item: any) => item.specialty !== userDetails?.speciality)));
    }, [specialityListApiData, userDetails?.speciality]);


    const handleAskSpecialist = () => {
        setStartConversation(true);
    };

    const handleNextClick = () => {
        navigate(ROUTES.MP.ACTIVE_SESSION_LIST);
    };

    // Complete Session Action
    const completeSession = () => {
        //TODO: Improve Payload according to saved data
        const payload = {
            "id": userDetails?.cid,
            "visitId": currentConsulatationDetails?.visitId,
            "order": currentConsulatationDetails?.consulationOrder
        };

        new MedicalProfessionalService(payload, {})
        .completeTask()
        .then((res) => {
            setSkeleton(false);
            dispatch(
                commonActions.SHOW_SNACKBAR({
                    show: true,
                    message: res.message,
                    success: true
                })
            );
            setTimeout(() => {
                navigate(ROUTES.MP.ACTIVE_SESSION_LIST);
            }, 100);
        })
        .catch((err) => {
            setSkeleton(false);
            dispatch(
              commonActions.SHOW_SNACKBAR({
                  show: true,
                  message: err.message,
                  success: false
              })
          );
        });
    }

    const columns = [
        { id: "date", label: t("common.date"), width: "10%" },
        { id: "organizationName", label: t("user_chat_history.organization_name"), width: "20%" },
        { id: "reasonForVisit", label: t("user_chat_history.reason_for_visit"), width: "40%", className:"wpx_text_style" },
        { id: "doctorName", label: t("user_chat_history.doctor_name"), width: "20%" },
        { id: "actions", label: t("common.action"), width: "10%" },
    ];

    const handleReportClick = () => {
        setIsInsightOpen(true);
    };

    const handleHelpClick = () => {
        navigate(ROUTES.MP.USER_CONSULTATION_LIST);
    };

    const handleSecondOpinion = () => {
        setIsChatScreenOpen(true);
    };

    const handleContinueChat = () => {
        setIsChatScreenOpen(true);
    };

    const handleInsightModel = () => {
        setIsInsightOpen(false);
    };

    const handleMPReference = () => {
        navigate(ROUTES.MP.REFER_SPECIALIST);
    };

    const handleCustomAction = (rowData: any) => {
        return (
            <>
                {rowData?.chatCompleted ? (
                    <>
                        <IconButton onClick={() => handleReportClick()}><SummarizeIcon /></IconButton>
                        {rowData?.multipleConsultation && (<IconButton onClick={() => handleHelpClick()}> <HelpOutlineIcon /></IconButton> )} 
                    </> 
                ):(
                    <IconButton onClick={() => handleContinueChat()}> <ChatIcon /></IconButton> )}
            </>
        
        );
    };

    /* Handles page and limit changes for pagination */
    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };

    return (
        <Container>
            <Grid2 container size={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{ py: 4, pl: 2, display: "flex", justifyContent: "space-between", width: "100%", alignContent: "center" , alignItems: "center" }}>
                <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{display: "flex", justifyContent:"space-between"}}>
                    <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                        <Typography className="wpx_chat_heading">{chatHistoryList?.name}</Typography>
                        <Typography className="wpx_chat_subheading">{chatHistoryList?.gender}, {chatHistoryList?.age}</Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                        <Button
                            className="wpx_save__btn"
                            variant="contained"
                            label={t("chat_history.ask_to_specialist")}
                            onClick={handleAskSpecialist}
                            disabled={!selectedChatHistory}
                        />
                        <Button
                            className="wpx_save__btn"
                            variant="contained"
                            label={t("chat_history.second_opinion")}
                            onClick={handleSecondOpinion}
                            disabled={!selectedChatHistory}
                        />
                        <Button
                            className="wpx_save__btn"
                            variant="contained"
                            label={t("chat_history.refer_mp")}
                            startIcon={<CoPresentIcon />}
                            onClick={handleMPReference}
                            disabled={!selectedChatHistory}
                        />
                        <Button
                            className="wpx_save__btn"
                            variant="contained"
                            label={isChatStart ? t("common.cancel"):  t("chat_history.next_patient")}
                            onClick={handleNextClick}
                        />
                        <Button
                            className="wpx_save__btn"
                            variant="contained"
                            label={t("chat_history.complete_session")}
                            onClick={completeSession}
                        />
                    </Grid2>

                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} size={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignContent: "center" }}>

                {skeleton ? (
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                        count={15}
                    />
                ) : (
                    <><Table
                        data={chatHistoryList?.history}
                        columns={columns}
                        requireCheckbox={true}
                        setSelectedChatHistory={setSelectedChatHistory}
                        handleForward={handleReportClick}
                        handleCustomAction={handleCustomAction} />
                    <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        rowsPerPage={limit}
                        rowsPerPageChange={setLimit} />
                    </>
                )}
            </Grid2>

            <ChatSession
                startConversation={startConversation}
                setStartConversation={setStartConversation}
                specialityList={specialityList}
                userRole={userRole}
                isChatScreenOpen={isChatScreenOpen}
                setIsChatScreenOpen={setIsChatScreenOpen}
                specialities={chatHistoryList?.specialities}
                setIsChatStart={setIsChatStart}
            />
            <Insight
                handleInsightModel={handleInsightModel}
                isInsightOpen={isInsightOpen}
            />
        </Container>
    );
};

export default UserChatHistoryList;
