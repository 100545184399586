import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class CompleteConsultationService {
    private axios;
    private payload;
    private headers;
    private baseURL;
    private localURL;
    private dummyCheck;

    constructor(payload = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.localURL = API.APP_URL;
        this.dummyCheck = API.DUMMY_CHECK;
        this.axios = new Axios();
    }

    async completeConsultaion() {
        const response = this.dummyCheck === "true" ?
            await this.axios.get(this.localURL + mockAPIPath.CREATE_VISIT_LIST, this.payload, this.headers):
            await this.axios.post(`${this.baseURL}${serverAPIPath.CREATE_VISIT_LIST}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
}

export default CompleteConsultationService;