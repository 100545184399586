class Cookies {

    private name: string;
    private data: string;
    
    constructor(name: string, data = "") {
        this.name = name;
        this.data = data;
    }

    read() {
        document.cookie.split(";").forEach((item) => item.includes(this.name) ? this.data = item.split("=")[1] : null );
        return this.data;
    }

    delete() {
        document.cookie = this.name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
    
}

export default Cookies;