import React, { Dispatch, SetStateAction, useState } from "react";
import ChatScreen from "./chat";
import FeedbackModal from "./feedback";
import Insight from "./insight";
import SelectSpecialistModal from "./SelectSpecialist";
import { specialityListType } from "../../types/CommonType";
import { assetPath } from "../../utils/assetPath";
import VisitModal from "./visitModal/VisitModal";

type propsType = { startConversation: boolean; setStartConversation: Dispatch<SetStateAction<boolean>>; specialityList: specialityListType; userRole: number | null | undefined; isChatScreenOpen: boolean; setIsChatScreenOpen: Dispatch<SetStateAction<boolean>>; specialities?: string; setIsChatStart?: Dispatch<SetStateAction<boolean>> ; chatSessionData?: {visitId: string }; setChatSessionData?: Dispatch<SetStateAction<{visitId: string }>>}

const ChatSession = (props: propsType) => {

    const { startConversation, setStartConversation, specialityList, userRole, isChatScreenOpen, setIsChatScreenOpen, specialities, setIsChatStart, chatSessionData, setChatSessionData } = props;

    const [inputVal, setInputVal] = useState<string>("");
    const [specialty, setSpecialty] = useState<{ id: number, specialty: string } | null>(null);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false);
    const [isInsightOpen, setIsInsightOpen] = useState<boolean>(false);
    const [isInsightAvailable, setIsInsightAvailable] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [openSpecialityModal, setOpenSpecialityModal] = useState<boolean>(false);


    interface ChatMessage {
        message: string;
        sender: string;
        type?: "text" | "image"; // Optional type property
        img: string;
    }

    const [messages, setMessages] = useState<ChatMessage[]>([
        { 
            message: "Hello! I believe you need my assistance to figure out your medical condition. What is the reason for your visit today?", 
            sender: "Assistant", 
            type: "text", 
            img: assetPath("images/chat/assistant-profile-pic.svg") 
        }
    ]);

    const handleInsightModel = () => {
        setIsInsightAvailable(false);
        setIsInsightOpen(false);
    };

    return (
        <>
            {/* Provide options to select Speciality conversation */}
            {openSpecialityModal && <SelectSpecialistModal
                isForwardModalOpen={openSpecialityModal}
                setIsForwardModalOpen={setOpenSpecialityModal}
                setSpecialty={setSpecialty}
                setInputVal={setInputVal}
                inputVal={inputVal}
                setIsChatScreenOpen={setIsChatScreenOpen}
                specialty={specialty}
                specialityList={specialityList}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
                userRole={userRole}
                isCompleted={isCompleted}
                setIsCompleted={setIsCompleted}
                chatSessionData={chatSessionData}
                setChatSessionData={setChatSessionData}
            />}

            {/* start chat with AI */}
            {isChatScreenOpen && <ChatScreen
                setIsFeedbackOpen={setIsFeedbackOpen}
                isChatScreenOpen={isChatScreenOpen}
                isInsightAvailable={isInsightAvailable}
                setIsInsightOpen={setIsInsightOpen}
                setIsChatScreenOpen={setIsChatScreenOpen}
                setIsInsightAvailable={setIsInsightAvailable}
                messages={messages}
                setMessages={setMessages}
                userRole={userRole}
                mpValue={inputVal}
                specialityValue={specialty?.specialty || specialities}
                setStartConversation={setOpenSpecialityModal}
                setIsCompleted={setIsCompleted}
                setIsChatStart={setIsChatStart}
                chatSessionData={chatSessionData}
                setChatSessionData={setChatSessionData}
            />}

            {/* Used to select a reason why patient discard chat conversation */}
            {isFeedbackOpen && <FeedbackModal
                isFeedbackOpen={isFeedbackOpen}
                setIsChatScreenOpen={setIsChatScreenOpen}
                setIsFeedbackOpen={setIsFeedbackOpen}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
            // setIsInsightAvailable={setIsInsightAvailable}
            />}

            {/* Provide insight of a chat conversation */}
            {isInsightOpen && <Insight
                handleInsightModel={handleInsightModel}
                isInsightOpen={isInsightOpen}
            />}

            {startConversation && <VisitModal
                openVisitModal={startConversation}
                setOpenVisitModal={setStartConversation}
                setOpenSpecialityModal={setOpenSpecialityModal}
                chatSessionData={chatSessionData}
                setChatSessionData={setChatSessionData}
            />}
        </>
    );
};

export default ChatSession;
