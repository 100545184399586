import React from "react";
import  AutoCompleteMUI  from "@mui/material/Autocomplete";
import { Chip, TextField, Typography } from "@mui/material";

interface GenericAutocompleteProps<T> {
  label: string;
  options: T[];
  getOptionLabel: (option: T) => string;
  isLoading?: boolean;
  value?: T | T[] | null;
  onChange?: (event: React.SyntheticEvent, newValue: T | T[] | null) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string
  multiple?:boolean;
  dropdownOpen?:boolean;
  setDropdownOpen?: any;
}

const AutoComplete = <T,>({
    label,
    options,
    getOptionLabel,
    isLoading = false,
    value,
    onChange,
    placeholder,
    dropdownOpen,
    setDropdownOpen,
    className,
    disabled = false,
    multiple = false
}: GenericAutocompleteProps<T>) => {

    const handleDropdownOpen = () => {
        if(setDropdownOpen){
            setDropdownOpen(true);
        }
    };

    const handleDropdownClose = () => {
        if(setDropdownOpen){
            setDropdownOpen(false);
        }
    };

    return (
        <>
            <Typography component="label">{label}</Typography>
            <AutoCompleteMUI
                multiple={multiple}
                value={multiple ? (value as T[] || []) : (value as T | null)}
                onChange={onChange}
                options={options}
                open={dropdownOpen}
                onOpen={handleDropdownOpen}
                onClose={handleDropdownClose}
                getOptionLabel={getOptionLabel}
                disabled={disabled}
                loading={isLoading}
                className={`${className} wpx_autoComplete`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                    />
                )}
                renderTags={(value: T[], getTagProps) =>
                    value?.map((option: T, index: number) => (
                        <Chip
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                            key={index}
                        />
                    ))
                }
            />
        </>
    );
};

export default AutoComplete;
