import { Container, Grid2, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Skeleton from "../../../components/common/skeleton";
import Table from "../../../components/common/table/Table";
import Insight from "../../../components/chatSession/insight";
import Button from "../../../components/common/button/Button";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";
import UserConsultaionListService from "../services/consultationListService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UserConsultaionList = () => {

    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [userConsultationList, setUserConsultationList] = useState<any>([]);
    const [skeleton, setSkeleton] = useState<any>(false);
    const [isInsightOpen, setIsInsightOpen] = useState<boolean>(false);


    useEffect(() => {
        const userConsultationList = () => {
            const payload = {};
            new UserConsultaionListService(payload).consultationList()
                .then((res) => {                    
                    setUserConsultationList(res);
                    setSkeleton(false);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message:err.message,
                            success:false
                        })
                    );           
                });
        };
        userConsultationList();
    }, [dispatch]);


    const handleInsightModel = () => {
        setIsInsightOpen(false);
    };

    const handleReportClick = () => {
        setIsInsightOpen(true);
    };

    const handleNextClick = () => {
        navigate(ROUTES.MP.USER_CHAT_HISTORY);
    };

    const columns = [
        { id: "date", label: t("common.date"), width: "15%" },
        { id: "reasonForVisit", label: t("common.reason_for_visit"), width: "55%", className:"wpx_text_style" },
        { id: "actionType", label: t("user_consultation.action_type"), width: "20%" },
        { id: "actions", label: t("common.action"), width: "10%" },
    ];

    const handleCustomAction = (rowData: any) => {
        return rowData?.chatCompleted ? (
            <IconButton onClick={handleReportClick}>
                <SummarizeIcon />
            </IconButton>
        ) : null; 
    };

    return(
        <Container>
            <Grid2 container spacing={2} mt={4}>
                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 10 }}>
                    <Typography>{t("user_consultation.my_consultaion")}</Typography>
                    <Typography>For {userConsultationList?.name}, {userConsultationList?.gender}, {userConsultationList?.age}</Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 2 }}>
                    <Button
                        className="wpx_save__btn"
                        variant="contained"
                        label={t("user_consultation.back_to_history")}
                        onClick={handleNextClick}
                        startIcon={<ArrowBackIcon/>}
                    />
                </Grid2>
            </Grid2>

            <Grid2 mt={2}>
                {skeleton ? (
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                        count={15}
                    />
                ) : (
                    <Table
                        data={userConsultationList?.list}
                        columns={columns}
                        handleCustomAction={handleCustomAction}
                    />
                )}
            </Grid2>
            <Insight
                handleInsightModel={handleInsightModel}
                isInsightOpen={isInsightOpen}
            />
        </Container>
    );
};

export default UserConsultaionList;