import { API } from "../../../config/api.config";
import Axios from "../../../utils/axios";
import { serverAPIPath } from "../config/apiPath";

class SpecialityService {

    private axios;
    private payload;
    private headers;
    private baseURL;

    constructor(payload = {}) {
        this.headers = {};
        this.payload = payload;
        this.baseURL = API.BASE_URL;
        this.axios = new Axios();
    }

    // Get list for all medical professional in an organization
    async specilitiesList() {
        const response = await this.axios.get(`${this.baseURL}${serverAPIPath.SPECIALITY_LIST}`, this.payload, this.headers);
        if(response?.data?.success) {
            return response?.data?.data;
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    };
};

export default SpecialityService;