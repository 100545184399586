import React from "react";
import { Box, Typography } from "@mui/material";
import { CONSTANTS } from "../../../../constants/constants";
import { useTranslation } from "react-i18next";
import ToggleButton from "../../../../components/common/button/ToggleButton";

type PropsType = {
    userRole: number;
    setUserRole: (userRole: number) => void;
}

const RoleSelection = ({userRole, setUserRole}: PropsType) => {

    const {t} = useTranslation();

    const handleRoleChange = (e: React.MouseEvent<HTMLElement>, newValue: number | null) => {
        if (newValue !== null) {
            setUserRole(newValue);
        }
    };
    
    const roles = [
        { label: t("common.super_admin"), value: CONSTANTS.SUPER_ADMIN},
        { label: t("common.organization_admin"), value: CONSTANTS.ORG_ADMIN},
        { label: t("common.medical_professional"), value: CONSTANTS.MEDICAL_PROF},
        { label: t("common.customer_service_representative"), value: CONSTANTS.CUSTOMER_SUPPORT},
    ];

    return (
        <>
            <Box className = "wpx_login__subContainer">
                <Typography variant = "h5">
                    {t("role_selection.select_role")}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    {t("common.introduction")}
                </Typography>
            </Box>
            <ToggleButton options={roles} value={userRole} onChange={handleRoleChange} className="wpx_login__roleSelection__toggleBtnGroup"/>
        </>
    );
};

export default RoleSelection;