import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Home from "./pages/Home";
import RoleSelection from "./pages/RoleSelection";
import SocialAccountSelection from "./pages/SocialAccountSelection";
import Cookies from "../../../utils/cookies";
import { useNavigate } from "react-router-dom";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";
import AuthService from "../services/authService";

const Login = () => {

    const [userRole, setUserRole] = useState <number> (0);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const cookie = new Cookies("user").read();

        if(cookie) {

            const payload = {};
            new AuthService(payload).claimList()
                .then((res) => {
                    dispatch(commonActions.USER_DETAILS({"userRole": res?.role, "name": res?.name, "memberIds": res?.mids, "orgIds": res?.orgs, "cid": res?.cid, "specialityIds": res?.sids}));
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err.message,
                            success: false
                        })
                    );          
                });
            //TODO: Get role from claim API
            setUserRole(Number(JSON.parse(decodeURIComponent(cookie))?.role));
            if(Number(JSON.parse(decodeURIComponent(cookie))?.role)) {
                const role = Number(JSON.parse(decodeURIComponent(cookie))?.role);
                const userRoute = role === 1 ? "/superadmin/manage/org" : role === 2 ? "/orgadmin/organization/select" : role === 3 ? "/csr/organization/select" : "/medprof/organization/select";
                navigate(`${userRoute}`);
                dispatch(commonActions.USER_ROLE(role));
                dispatch(commonActions.IS_LOGIN(true));
            }
        }
    }, [navigate, dispatch]);

    return(
        <Container className = "wpx_centeredContainer">
            <Home/>
            <RoleSelection userRole={userRole} setUserRole={setUserRole} />
            {userRole ?
                <SocialAccountSelection userRole={userRole} />
                :
                null}
        </Container>
    );
};

export default Login;