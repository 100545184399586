import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import DialogModal from "../../../components/common/modal/DialogModal";
import {
    Box,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InputField from "../../../components/common/input/InputField";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AddIcon from "@mui/icons-material/Add";
import Skeleton from "../../../components/common/skeleton";
import { commonActions } from "../../../store/features/commonSlice";
import { useAppDispatch } from "../../../store/hooks";
import { ROUTES } from "../../../constants/routes";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import OrganizationService from "../services/organizationService";


interface orgData {
    organization?: string,
    location?: string,
    orgId?:string
}

const ManageOrganizationList = () => {
    const [t] = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [orgList, setOrgList] = useState<any>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<boolean>(true);
    const [orgData, setOrgData] = useState<orgData>({ organization: "", location: "" });
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [refreshList, setRefreshList] = useState<boolean>(false);

    useEffect(() => {
        const organizationList = () => {
            setRefreshList(false);
            const payload = {};
            new OrganizationService(payload).organizationList()
                .then((res) => {
                    setOrgList(res);
                    setSkeleton(false);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message:err.message,
                            success:false
                        })
                    );           
                });
        };

        organizationList();
    }, [refreshList, dispatch]);

    const tableData =
        orgList?.map((item: any, index: number) => ({
            id: (index + 1),
            organization: item?.name,
            location: item?.location,
            orgId: item?.id
        })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%" },
        { id: "organization", label: t("organization.org_name"), width: "40%" },
        { id: "location", label: t("organization.location"), width: "30%" },
        { id: "actions", label: t("common.action"), width: "20%" },
    ];

    const handleEdit = (user: any) => {
        const tempData = { organization: user.organization, location: user?.location, orgId:user?.orgId };
        setOrgData(tempData);
        setIsEditModalOpen(true);
        setShowValidation(false);
    };

    const handleDelete = (user: any) => {
        setIsDeleteModalOpen(true);
        setOrgData(user);
    };

    const handleOrgDelete = () => {
        new OrganizationService().deleteOrganization(orgData.orgId)
            .then((res) => {
                setRefreshList(true);
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: res.message
                    })
                );
                setIsDeleteModalOpen(false);
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err.message,
                        success: false
                    })
                );
                setIsDeleteModalOpen(false);
            });
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const handleCloseAddModal = () => {
        setIsAddModalOpen(false);
    };

    const handleFieldChange = (e: any, fieldName: string) => {
        const tempData: any = { ...orgData };
        tempData[fieldName] = e.target.value;
        setOrgData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setIsAddModalOpen(true);
        setOrgData({ organization: "", location: "", orgId:"" });
    };

    const handleAddOrganization = () => {
        setShowValidation(true);
        if (orgData?.organization?.trim() && orgData?.location?.trim()) {
            const payload = {
                "name": orgData?.organization.trim(),
                "location": orgData?.location?.trim()
            };
            new OrganizationService(payload).addOrganization()
                .then((res) => {
                    setRefreshList(true);
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: res.message
                        })
                    );
                    setIsAddModalOpen(false);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err.message,
                            success: false
                        })
                    );
                    setIsAddModalOpen(false);
                });
        }
    };

    const handleNavigate = (user: any) => {
        const orgName = user.organization;
        const orgId = user.orgId;
        navigate(ROUTES.SA.MANAGE_OA, { state: { orgName, orgId } });
    };

    const handleCustomAction = (rowData: any) => {
        return (
            <IconButton onClick={() => handleNavigate(rowData)} >
                <PeopleIcon className="wpx_action__btn_icon" />
            </IconButton>
        );
    };

    const handleEditOrganization = () => {
        setShowValidation(true);
        if (orgData?.organization?.trim() && orgData?.location?.trim()) {
            const payload = {
                "name": orgData?.organization.trim(),
                "location": orgData?.location?.trim()
            };
            new OrganizationService(payload).updateOrganization(orgData.orgId)
                .then((res) => {
                    setRefreshList(true);
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: res.message
                        })
                    );
                    setIsEditModalOpen(false);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err.message,
                            success: false
                        })
                    );
                    setIsEditModalOpen(false);
                });
        }
    };

    return (
        <Container>
            {skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    count={15}
                />
            ) : (              
                <Table
                    data={tableData}
                    columns={columns}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    handleCustomAction={handleCustomAction}
                />
            // TODO: Pagination need to be added in future
            )}
            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={handleAddBtnClick}
                    startIcon={<AddIcon />}
                />
            </Box>
            <DialogModal open={isDeleteModalOpen}>
                <Box sx={{ py: 2 }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.delete_confirmation_msg")}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.msg_to_proceed")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleOrgDelete}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleCloseDeleteModal}
                        />
                    </Box>
                </Box>
            </DialogModal>
            <DialogModal open={isEditModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml: "10px", justifyContent: "space-between" }}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>{t("organization.edit_organization")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField value={orgData?.organization} onChange={(e) => { handleFieldChange(e, "organization"); }} variant="outlined" label="Organization" placeHolder="Enter organization name" />
                                {
                                    showValidation && !orgData?.organization?.trim() ?
                                        <Typography color="error">{t("organization.enter_organization")}</Typography>
                                        : null
                                }
                                <InputField value={orgData?.location} onChange={(e) => { handleFieldChange(e, "location"); }} variant="outlined" label="Location" placeHolder="Enter location" />
                                {
                                    showValidation && !orgData?.location?.trim() ?
                                        <Typography color="error">{t("organization.enter_location")}</Typography>
                                        : null
                                }
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}
                                    onClick={handleEditOrganization}
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>
            <DialogModal open={isAddModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml: "31px", justifyContent: "space-between" }}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>{t("organization.add_organization")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseAddModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    className=""
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={orgData?.organization ? orgData?.organization : ""} onChange={(e) => { handleFieldChange(e, "organization"); }} variant="outlined" label="Organization" placeHolder="Enter organization name" />
                                    {
                                        showValidation && !orgData?.organization?.trim() ?
                                            <Typography color="error">{t("organization.enter_organization")}</Typography>
                                            : null
                                    }
                                    <InputField value={orgData?.location ? orgData?.location : ""} onChange={(e) => { handleFieldChange(e, "location"); }} variant="outlined" label="Location" placeHolder="Enter location" />
                                    {
                                        showValidation && !orgData?.location?.trim() ?
                                            <Typography color="error">{t("organization.enter_location")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddOrganization}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>

        </Container>
    );
};

export default ManageOrganizationList;
