import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    Paper,
    Stack,
    TableContainer,
    Typography,
} from "@mui/material";
import Table from "../../../components/common/table/Table";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Button from "../../../components/common/button/Button";
import DialogModal from "../../../components/common/modal/DialogModal";
import InputField from "../../../components/common/input/InputField";
import AutoComplete from "../../../components/common/input/AutoComplete";
import { CONSTANTS } from "../../../constants/constants";

import Skeleton from "../../../components/common/skeleton";
import { commonActions } from "../../../store/features/commonSlice";
import { useDispatch } from "react-redux";
import { REGEX } from "../../../constants/regex";
import MedProfService from "../services/medProfService";
import { useAppSelector } from "../../../store/hooks";
import SpecialityService from "../services/specialitiesService";


const ManageMedProfList = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const organizationDetails = useAppSelector((state) => state.common.curentOrgList);
    const orgId = organizationDetails?.selectOrg?.id ?? "";

    const [mpList, setMpList] = useState<any>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [addDataModalopen, setAddDataModalOpen] = useState(false);
    const [specialities, setSpecialties] = useState<any>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [mpData, setMPData] = useState<any>(null);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [selectedSpeciality, setSelectedSpeciality] = useState<any>(null);
    const [emailVaildation, setEmailValidation] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setDropdownOpen(false);
    };

    const addDataCloseDataModal = () => {
        setAddDataModalOpen(false);
        setDropdownOpen(false);
    };


    const handleEditSpecialty = (event: React.SyntheticEvent, newValue: any) => {
        setSelectedSpeciality(newValue);
    };

    const handleEdit = (user: any) => {
        setIsEditModalOpen(true);
        setShowValidation(false);
        setMPData(user);

    };
    const handleDelete = (user: any) => {
        setMPData(user);
        setIsDeleteModalOpen(true);
    };

    useEffect(() => {
        // Function to Get the list of all medical professional in an organization
        const MPList = () => {
            const payload = {};
            new MedProfService(payload).medProfList(orgId)
                .then((res) => {
                    setMpList(res);
                    setSkeleton(false);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err?.message,
                            success: false
                        })
                    );
                });
        };

        // Function to Get all the list of specialities
        const specialitiesList = () => {
            const payload = {};
            new SpecialityService(payload).specilitiesList()
                .then((res) => {
                    setSpecialties(res);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err?.message,
                            success: false
                        })
                    );
                });
        };
        MPList();
        specialitiesList();
    }, [refreshList, dispatch, orgId]);

    const tableData =
        mpList?.map((item: any, index: number) => ({
            id: (index + 1),
            name: item.doctorName,
            specialty: item.specialities.toString(),
            email: item.email,
            memberId: item?.id
        })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%" },
        { id: "name", label: t("manage_mp_list.doctor_name"), width: "25%" },
        { id: "specialty", label: t("common.specialty"), width: "25%" },
        { id: "email", label: t("common.email"), width: "25%" },
        { id: "actions", label: t("common.action"), width: "25%" },
    ];


    const handleFieldChange = (e: any, fieldName: string) => {
        if (fieldName === "email") {
            if (REGEX.EMAIL_VALIDATION.test(e.target.value)) {
                setEmailValidation(false);
            }
        }
        const tempData = { ...mpData };
        tempData[fieldName] = e.target.value;
        setMPData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setAddDataModalOpen(true);
        setMPData(null);
        setSelectedSpeciality(null);
    };

    // Function to handle adding a new medical professional in an organization
    const handleAddOrgAdmin = () => {
        setShowValidation(true);
        if (!CONSTANTS.EMAIL_REGEX.test(mpData?.email) && mpData?.email) {
            setEmailValidation(true);
        } else {
            setEmailValidation(false);
            if (mpData?.name?.trim() && mpData?.email && selectedSpeciality?.length > 0) {
                const specialitiesIdArr: Array<string> = selectedSpeciality?.map((item: any) => {
                    return item?.id;
                });
                const payload = {
                    orgId: orgId,
                    name: mpData?.name.trim(),
                    email: mpData?.email,
                    specialities: specialitiesIdArr
                };
                new MedProfService(payload).addMedProf()
                    .then((res) => {
                        dispatch(
                            commonActions.SHOW_SNACKBAR({
                                show: true,
                                message: res?.message
                            })
                        );
                        setAddDataModalOpen(false);
                        setRefreshList(true);
                    }).catch((err) => {
                        dispatch(
                            commonActions.SHOW_SNACKBAR({
                                show: true,
                                message: err?.message,
                                success: false
                            })
                        );
                        setAddDataModalOpen(false);
                    });
            }
            setRefreshList(false);
        }
    };

    // Function to handle updating a medical professional in an organization
    const handleEditOrgAdmin = () => {
        setShowValidation(true);
        if (mpData?.name?.trim() && mpData?.email && selectedSpeciality?.length > 0) {
            const specialitiesIdArr: Array<string> = selectedSpeciality?.map((item: any) => {
                return item?.id;
            });
            const payload = {
                memberId: mpData?.memberId,
                name: mpData?.name.trim(),
                specialities: specialitiesIdArr
            };
            new MedProfService(payload).updateMedProf(orgId)
                .then((res) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: res?.message
                        })
                    );
                    setIsEditModalOpen(false);
                    setRefreshList(true);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err?.message,
                            success: false
                        })
                    );
                    setIsEditModalOpen(false);
                });
        }
        setRefreshList(false);
    };

    // Function to handle deleting a medical professional in an organization
    const handleDeleteMedProf = () => {
        const payload = { memberId: mpData?.memberId };
        new MedProfService(payload).deleteMedProf(orgId)
            .then((res) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: res?.message
                    })
                );
                setIsDeleteModalOpen(false);
                setRefreshList(true);
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err?.message,
                        success: false
                    })
                );
            });
        setRefreshList(false);
    };

    // Function to handle closing the delete model when not deleting a medical professional in an organization
    const handleCloseDelete = () => {
        setIsDeleteModalOpen(false);
    };


    return (
        <Container>
            <TableContainer component={Paper}>
                {skeleton ? (
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                        count={15}
                    />
                ) : (
                    <Table
                        data={tableData}
                        columns={columns}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                    // TODO: Pagination need to be added in future

                )}
            </TableContainer>

            <Button
                className="wpx_plus__btn"
                variant="contained"
                onClick={handleAddBtnClick}
                startIcon={<AddIcon />}
            />

            <DialogModal open={addDataModalopen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 4, mt: 2, justifyContent: "space-between" }}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>
                        {t("manage_mp_list.add_medical_professional")}
                    </Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={addDataCloseDataModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    className=""
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={mpData?.name ? mpData?.name : ""} onChange={(e) => { handleFieldChange(e, "name"); }} variant="outlined" label="Name" placeHolder="Enter name" />
                                    {
                                        showValidation && !mpData?.name?.trim() ?
                                            <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                            : null
                                    }
                                    <AutoComplete
                                        label="Select speciality"
                                        options={specialities}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedSpeciality}
                                        onChange={handleEditSpecialty}
                                        placeholder={t("manage_csr.search_for_speciality")}
                                        multiple={true}
                                        dropdownOpen={dropdownOpen}
                                        setDropdownOpen={setDropdownOpen}

                                    />
                                    {
                                        showValidation && !(selectedSpeciality?.length > 0) ?
                                            <Typography color="error">Please select speciality</Typography>
                                            : null
                                    }
                                    <InputField value={mpData?.email ? mpData?.email : ""} onChange={(e) => { handleFieldChange(e, "email"); }} variant="outlined" label="Email" placeHolder="Enter email" />
                                    {
                                        showValidation && !mpData?.email?.trim() ?
                                            <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                            : null
                                    }
                                    {
                                        emailVaildation && mpData?.email?.trim() ? <Typography color="error">{t("common.valid_email")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddOrgAdmin}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>

            <DialogModal
                open={isDeleteModalOpen}
            >
                <Box sx={{ py: 2 }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("manage_mp_list.delete_confirmation_message")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleDeleteMedProf}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleCloseDelete}
                        />
                    </Box>
                </Box>
            </DialogModal>

            <DialogModal open={isEditModalOpen} >
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 4, mt: 2, justifyContent: "space-between" }}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>{t("common.edit_profile")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField
                                    value={mpData?.name}
                                    onChange={(e) => { handleFieldChange(e, "name"); }}
                                    variant="outlined"
                                    placeHolder="Enter name"
                                    label="Name"

                                />
                                {
                                    showValidation && !mpData?.name?.trim() ?
                                        <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                        : null
                                }
                                <AutoComplete
                                    label="Select speciality"
                                    options={specialities}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedSpeciality}
                                    onChange={handleEditSpecialty}
                                    className="wpx_autocomplete_style"
                                    placeholder={t("manage_csr.search_for_speciality")}
                                    multiple={true}
                                    dropdownOpen={dropdownOpen}
                                    setDropdownOpen={setDropdownOpen}
                                />
                                {
                                    showValidation && !(selectedSpeciality?.length > 0) ?
                                        <Typography color="error">{t("manage_csr.select_speciality")}</Typography>
                                        : null
                                }
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}
                                    onClick={handleEditOrgAdmin}
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>
        </Container>
    );
};

export default ManageMedProfList;
