
import React from "react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface InputFieldProps {
  label?: string;
  value?: any;
  variant?: "outlined" | "filled" | "standard";
  disabled?: boolean;
  id?: string;
  name?: string;
  type?: string;
  key?: string;
  className?: string;
  placeHolder?: string;
  customIcon?: boolean;
  customIconElement?:() => React.JSX.Element;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const InputField = (props:InputFieldProps) => {

    const {label, value, variant, id, disabled, className,  onChange, placeHolder, name, onFocus, onBlur, onKeyDown, type, customIcon = false, customIconElement} = props;
    return (
        <>
            <Typography component="label">{label}</Typography>
            <TextField
                id={id}
                onFocus={onFocus}
                onBlur={onBlur}
                value={value}
                name={name}
                type={type}
                placeholder={placeHolder}
                variant={variant}
                disabled={disabled}
                onChange={onChange}
                className={`${className} wpx_input `}
                fullWidth
                onKeyDown={onKeyDown}
                slotProps={{
                    input: {
                        endAdornment: customIcon ? (
                            customIconElement ? customIconElement() :
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                        ) : null,
                    },
                }}  
            />
        </>
    );
};

export default InputField;
