import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { assetPath } from "../../utils/assetPath";
import SideNavbar from "./SideNavbar";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { commonActions } from "../../store/features/commonSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ROUTES } from "../../constants/routes";
import { CONSTANTS } from "../../constants/constants";
import Snackbar from "./snackbar/snackbar";
import AuthService from "../../modules/auth/services/authService";
import Cookies from "../../utils/cookies";
 
 
function ResponsiveAppBar() {
    const organizationDetails = useAppSelector((state) => state.common.curentOrgList);
  
    const [anchorElUser, setAnchorElUser] = useState<boolean>(false);
    const [organization, setOrganization] = useState<string>("");
    const [t] = useTranslation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userRole = useAppSelector((state) => state.common.userRole);
    const isLogin = useAppSelector((state) => state.common.isLogin);
    const userDetails = useAppSelector((state) => state.common.userDetails);
 
    const handleOpenUserMenu = () => {
        setAnchorElUser(true);
    };

    const handleLogout = () => {
        const payload = {};
        new AuthService(payload).logout()
            .then(() => {
                setAnchorElUser(false);
                navigate(ROUTES.ROOT);
                dispatch(commonActions.IS_LOGIN(false));
                dispatch(commonActions.USER_ROLE(null));
                new Cookies("user").delete();
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err.message,
                        success: false
                    })
                );
            });
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(false);
    };

    /* Dynamically update organization when `organizationDetails` changes */
    useEffect(() => {
        if (organizationDetails?.selectOrg?.id) {
            setOrganization(organizationDetails.selectOrg.id);
        }
    }, [organizationDetails]);

    const handleOrganizationChange = (event:string) =>{
        const selectedOrg = organizationDetails?.orgList?.filter((org)=>(org?.id === event))[0];
        setOrganization(event);
        if(selectedOrg){
            dispatch(commonActions.CURRENT_ORG_LIST({
                orgList: organizationDetails?.orgList,
                selectOrg: selectedOrg
            }));
        }
    };
    const { show, message, success } = useAppSelector(state => state.common.snackbarInfo);
    const handleClose = () => {
        dispatch(      
            commonActions.SHOW_SNACKBAR({      
                show: false       
            })       
        );       
    };
 
    return (
        isLogin ? (
            <>
                <AppBar position="static" className="wpx_header">
                    <Toolbar sx={{ backgroundColor: "#111827" }}>
                        <SideNavbar />

                        <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center" }}>
                            <img
                                src={assetPath("images/logos/light-logo.svg")}
                                alt="warp-x-logo"
                                style={{ maxHeight: "40px" }}
                            />
                        </Box>

                        <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1, justifyContent: "center" }}>
                            <img
                                src={assetPath("images/logos/light-logo.svg")}
                                alt="warp-x-logo"
                                style={{ maxHeight: "40px" }}
                            />
                        </Box>

                        <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center", marginLeft: "40px"  }}>
                            {userRole === CONSTANTS.ORG_ADMIN || userRole === CONSTANTS.MEDICAL_PROF ?
                                <FormControl fullWidth sx={{ pl: 0 }}>
                                    <Select
                                        labelId="Fortis Cancer Institute, Defence Colony, New Delhi"
                                        className="wpx_header__select_organization"
                                        value={organization ? organization : t("common.no_organization")}
                                        label="Fortis Cancer Institute, Defence Colony, New Delhi"
                                        variant="outlined"
                                        onChange={(e: { target: { value: string; }; }) => handleOrganizationChange(e.target.value)}
                                        sx={{
                                            height: "50px",
                                            "& fieldset": {
                                                border: "none",
                                            },
                                            p: "0px !important",
                                            color: "#FFFFFF"
                                        }}
                                    >

                                        {organizationDetails?.orgList?.map((org: { id: string; name: string; location: string }) => (
                                            <MenuItem key={org.id} value={org.id}>
                                                {org.name}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                : null}
                        </Box>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {
                                        userDetails?.profile ? 
                                            <Avatar alt={userDetails?.name} src={userDetails?.profile} />
                                            : 
                                            <Avatar>{userDetails?.name?.charAt(0)}</Avatar>
                                    } 
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={anchorElUser}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={handleLogout}>
                                    <Typography sx={{ textAlign: "center" }}>{t("common.logout")}</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center", marginLeft: "30px"  }}>
                    {userRole === CONSTANTS.ORG_ADMIN || userRole === CONSTANTS.MEDICAL_PROF ?
                        <FormControl fullWidth sx={{ pl: 0 }}>
                            <Select
                                labelId="Fortis Cancer Institute, Defence Colony, New Delhi"
                                className="wpx_header__select_organization"
                                value={organization ? organization : t("common.no_organization")}
                                label="Fortis Cancer Institute, Defence Colony, New Delhi"
                                variant="outlined"
                                onChange={(e: { target: { value: string; }; }) => handleOrganizationChange(e.target.value)}
                                sx={{
                                    height: "50px",
                                    "& fieldset": {
                                        border: "none",
                                    },
                                    p: "0px !important"
                                }}
                            >
                                {organizationDetails?.orgList?.map((org: { id: string; name: string; location: string }) => (
                                    <MenuItem key={org.id} value={org.id}>
                                        {org.name}
                                    </MenuItem>
                                ))}
                                
                            </Select>
                        </FormControl>
                        : null}
                </Box>
                {/* Snackbar utility for Common Call
                - Need to use boolean check for importing Snackbar because second call is not possible
                - Without the boolena import - Calling again is not possible since it calls handleClose without duration wait
                TODO: Need to update it according to Success and Failure measure
            */}
                {show ?
                    <Snackbar
                        open={show}
                        duration={3000}
                        onClose={handleClose}
                        message={message}
                        success={success}
                    />
                    : null
                }
 
            </>
        ) : null
    );
}
export default ResponsiveAppBar;