export const mockAPIPath = {
    MP_LIST: "/json-schemas/MpList.json",
    CSR_LIST: "/json-schemas/CSRList.json",
    SPECIALITY_LIST: "/json-schemas/SpecialityList.json"
};

export const serverAPIPath = {
    MP_LIST: "/user/medprof",
    ADD_MP: "/user/medprof/save",
    EDIT_MP: "/user/medprof",
    DELETE_MP: "/user/medprof",
    SPECIALITY_LIST: "/speciality",
    CSR_LIST: "/user/representative",
    ADD_CSR: "/user/representative/save",
    EDIT_CSR: "/user/representative",
    DELETE_CSR: "/user/representative",
};
