import axios from "axios";
import Cookies from "./cookies";

class Axios {

    async get(uri: string, payload: object = {}, headers: object = {}) {
        const response = await axios({
            method: "GET",
            url: uri,
            data: payload,
            headers: headers,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error);
        });
        return response;
    }

    async post(uri: string, payload: object = {}, headers: object = {}) {
        const response = await axios({
            method: "POST",
            url: uri,
            data: payload,
            headers: { "X-XSRF-TOKEN": new Cookies("XSRF-TOKEN").read(), ...headers },
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error);
        });
        return response;
    }

    async put(uri: string, payload: object = {}, headers: object = {}) {
        const response = await axios({
            method: "PUT",
            url: uri,
            data: payload,
            headers: { "X-XSRF-TOKEN": new Cookies("XSRF-TOKEN").read(), ...headers },
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error);
        });
        return response;
    }

    async delete(uri: string, payload: object = {}, headers: object = {}) {
        const response = await axios({
            method: "DELETE",
            url: uri,
            data: payload,
            headers: { "X-XSRF-TOKEN": new Cookies("XSRF-TOKEN").read(), ...headers },
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error);
        });
        return response;
    }

    handleCatchOfAxios(error: any) {
        return error?.message;
    }
}

export default Axios;