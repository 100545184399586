import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import CommonType, { commonInitialValue } from "../../interfaces/CommonInterface";

const initialState: CommonType = commonInitialValue;

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        USER_ROLE: (state, action: PayloadAction<number | null>) => {
            state.userRole = action.payload;
        },
        IS_LOGIN: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload;
        },
        SHOW_SNACKBAR: (state, action: PayloadAction<any>) => {
            state.snackbarInfo = action.payload;
        },
        USER_DETAILS: (state, action: PayloadAction<any>) => {
            state.userDetails = action.payload;
        },
        CURRENT_ORG_LIST: (state, action: PayloadAction<any>) => { // Use to save Current Organization list and selected Organization
            state.curentOrgList = action.payload;
        },
        CURRENT_CONSULTATION: (state, action: PayloadAction<any>) => { // Use to save current ongoing consultation
            state.currentConsultation = action.payload;
        }
    }
});

export default commonSlice.reducer;
export const commonActions = commonSlice.actions;