import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/common/button/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";

const NoOrganization = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate(ROUTES.ROOT);
    };

    return (
        <>
            <Box className="wpx_organizationSelection__subContainer">
                <Typography variant="h5">
                    {t("no_organization.no_organization")}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    {t("no_organization.request_admin_to_assign_org")}
                </Typography>
            </Box>
            <Box>
                <Button
                    className="wpx_organizationSelection__noOrganization__Btn"
                    label={t("no_organization.request_admin")}
                />
                <Button
                    className="wpx_organizationSelection__noOrganization__Btn"
                    label={t("common.logout")}
                    onClick={handleLogout}
                />
            </Box>
        </>
    );
};

export default NoOrganization;
