import React from "react";
import TablePagination from "@mui/material/TablePagination";
import { Container } from "@mui/material";
import { PaginationType } from "../../../types/PaginationType";

const Pagination = (props:PaginationType) => {

    const {currentPage, totalCount, pageChange, rowsPerPage, rowsPerPageChange} = props;
    
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {  
        pageChange(newPage + 1, rowsPerPage);    
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        rowsPerPageChange(parseInt(event.target.value ));
        pageChange(1, parseInt(event.target.value ));    
    };

    return (
        <Container>
            <TablePagination
                className="wpx_pagination_style"
                component="div"
                count={totalCount}
                page={currentPage - 1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage= "Rows per page"
            />
        </Container>
    );
};

export default Pagination;
