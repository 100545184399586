export const mockAPIPath = {
    ORG_LIST: "/json-schemas/OrganizationList.json",
    CSR_LIST: "/json-schemas/CSRList.json",
    ORG_ADMIN_LIST: "/json-schemas/OrgAdminList.json"   
};

export const serverAPIPath = {
    ORG_LIST: "/organization", 
    ORG_ADMIN_LIST: "/user/orgadmin",
    ADD_ORG: "/organization/save",
    ADD_ORG_ADMIN: "/user/orgadmin/save"
};
