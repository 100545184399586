import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import useAxios from "../../../hooks/useAxios";
import { mockAPIPath } from "../config/apiPath";
import { Box, Container, Grid2, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/button/Button";
import ModeIcon from "@mui/icons-material/Mode";
import ChatSession from "../../../components/chatSession";
import { useAppSelector } from "../../../store/hooks";
import { specialityListType } from "../../../types/CommonType";
import Skeleton from "../../../components/common/skeleton";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatIcon from "@mui/icons-material/Chat";
import Pagination from "../../../components/common/table/Pagination";
import { CONSTANTS } from "../../../constants/constants";
import RefreshLoginService from "../services/refreshLoginService";
import { useDispatch } from "react-redux";
import { commonActions } from "../../../store/features/commonSlice";



const ChatHistory = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [chatHistoryList, setChatHistoryList] = useState<any>([]);
    // const [speciality, setSpeciality] = useState<string>("");
    const [startConversation, setStartConversation] = useState<boolean>(false);
    const [chatSessionData, setChatSessionData] = useState<{visitId: string }>({visitId: ""});

    const userRole = useAppSelector((state) => state.common.userRole);
    const userRoute = userRole === 1 ? "/superadmin" : userRole === 2 ? "/orgadmin" : userRole === 3 ? "/medprof" : "/csr";
    const [isChatScreenOpen, setIsChatScreenOpen] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(CONSTANTS.PAGINATION_LIMIT);
    const [currentPage, setCurrentPage] = useState<number>(CONSTANTS.FIRST_PAGE);


    const [specialityList, setSpecialtyList] = useState<specialityListType>([]);

    const dispatch = useDispatch();
    const { data: specialityListApiData } = useAxios({
        url: `${mockAPIPath.SPECIALITY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        setSpecialtyList(specialityListApiData?.data);
    }, [specialityListApiData]);


    const handleForwardClick = (rowData: any) => {       
        if (rowData.chatCompleted){
            setStartConversation(true);
        } else {
            setChatSessionData(rowData?.visitId);
            setIsChatScreenOpen(true);
        }
    };

    const handleEdit = () => {
        navigate(ROUTES.CSR.USER_REGISTRATION, {
            state: { 
                from: "edit",
                editData: {
                    aadhaar: "123456789012", 
                    name: "Rahul Misra",
                    mobile: "9876543210",
                    gender: "Male",
                    dob: "01/01/1984"
                }
            }
        });
    };

    const handleStartSession = () =>{
        setStartConversation(true);
    };

    const chatHistoryListApiCall = useAxios({
        url: `${mockAPIPath.USER_CHAT_HISTORY_LIST}`,
        method: "GET",
    });

    useEffect(() => {
        const { data } = chatHistoryListApiCall;
        if (data) {
            setChatHistoryList(data.data);
            if (data.totalCount && data.totalPages) {
                setTotalCount(data.totalCount);
            }
        }
        setSkeleton(false);
    }, [chatHistoryListApiCall]);

    const columns = [
        { id: "date", label: t("common.date"), width: "10%" },
        { id: "organizationName", label: t("chat_history.org_name"), width: "25%" },
        { id: "reasonForVisit", label: t("chat_history.visit_reason"), width: "40%", className:"wpx_text_style" },
        { id: "doctorName", label: t("chat_history.doctors_name"), width: "15%" },
        { id: "actions", label: t("chat_history.actions"), width: "10%" },
    ];

    const handleCustomAction = (rowData: any) => {
        return (
            rowData?.chatCompleted ?  <IconButton  onClick ={() => handleForwardClick(rowData)} ><ArrowForwardIcon /> </IconButton> : <IconButton onClick ={() => handleForwardClick(rowData)}><ChatIcon /></IconButton>
        );
    };

    /* Handles page and limit changes for pagination */
    const handlePageChange = (newPage: number, newLimit: number) => {
        setCurrentPage(newPage);
        setLimit(newLimit);
    };

    const handleNextPatientButton = () => {
        const queryParams: any = {
            revoke: true
        };
        const payload = {};
        new RefreshLoginService(payload, queryParams).refreshLogin()
            .then((res) => {
                console.log(res);
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err.message,
                        success: false
                    })
                );
            });

    };

    return (
        <Container>
            <Grid2 container spacing={2} size={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{ py: 4, pl: 2, display: "flex", justifyContent: "space-between", width: "100%", alignContent: "center", alignItems: "center" }}>
                <Grid2 size={{ xs: 6, sm: 6, md: 8, lg: 8 }}>
                    <Typography className="wpx_chat_heading" >{chatHistoryList?.name} <IconButton onClick={handleEdit}><ModeIcon /></IconButton></Typography>
                    <Typography className="wpx_chat_subheading">{chatHistoryList?.gender}, {chatHistoryList?.age}</Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                    <Link to={`${userRoute}${ROUTES.COMMON.USER_VERIFICATION}`} onClick = {handleNextPatientButton} className="wpx_chat_icon">{t("chat_history.next_patient")}</Link>
                </Grid2>
            </Grid2>

            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={() => handleStartSession()}
                    startIcon={<AddIcon />}
                />
            </Box>
            {skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    count={15}
                />
            ) : (
                <><Table
                    data={chatHistoryList.history}
                    columns={columns}
                    // handleForward={handleForwardClick}
                    handleCustomAction={handleCustomAction} />
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={handlePageChange}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit} /></>
            )}
            <ChatSession
                startConversation={startConversation}
                setStartConversation={setStartConversation}
                specialityList={specialityList}
                userRole={userRole}
                isChatScreenOpen={isChatScreenOpen}
                setIsChatScreenOpen={setIsChatScreenOpen}
                chatSessionData={chatSessionData}
                setChatSessionData={setChatSessionData}
            />
        </Container>
    );
};

export default ChatHistory;