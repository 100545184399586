import React, { useEffect, useState } from "react";
import Table from "../../../components/common/table/Table";
import Grid from "@mui/material/Grid2";
import Select from "../../../components/common/input/Select";
import { Container, Typography } from "@mui/material";
import InputField from "../../../components/common/input/InputField";
import { useTranslation } from "react-i18next";
import Skeleton from "../../../components/common/skeleton";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import MedicalProfessionalService from "../services/medicalProfessionalService";
import { commonActions } from "../../../store/features/commonSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

const ActiveSessionList = () => {
    const [t] = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const [activeSessionList, setActiveSessionList] = useState<any>([]);
    const [assignedFilter, setAssignedFilter] = useState<string>(
        "Assigned to my speciality"
    );
    const userDetails = useAppSelector((state) => state.common.userDetails);
    const [timeFilter, setTimeFilter] = useState<string>("Today");
    const [search, setSearch] = useState<string>("");
    const [skeleton, setSkeleton] = useState<any>(true);

    // Redux Data
    const selectedOrg = useAppSelector((state) => state.common.curentOrgList?.selectOrg);
    const userRole = useAppSelector((state) => state.common.userRole);

    useEffect(() => {
        const activeSessionListApiCall = () => {
            const org_id = selectedOrg?.id;
            const payload = {};

            const queryParams: any = {
                org_id: org_id,
                member_id: userDetails?.memberIds,
                speciality_id: userDetails?.specialityIds,
                taskCategory: assignedFilter,
                dateFilter: null,
                search: null,
            };

            if (timeFilter) {
                queryParams.dateFilter = timeFilter;
            }

            if (search) {
                queryParams.search = search;
            }
            new MedicalProfessionalService(payload, queryParams)
                .activeTaskList()
                .then((res) => {
                    setActiveSessionList(res);
                    setSkeleton(false);
                })
                .catch((err) => {
                    setSkeleton(false);
                    dispatch(
                      commonActions.SHOW_SNACKBAR({
                          show: true,
                          message: err.message,
                          success: false
                      })
                  );
                });
        };

        activeSessionListApiCall();
    }, [ assignedFilter, search, selectedOrg?.id, timeFilter, userDetails, dispatch]);

    const assignedFilterOptions = [
        { value: "Assigned to my speciality", label: t("active_session_list.assign_speciality")},
        { value: "Assigned to me", label: t("active_session_list.assign_to_me") },
    ];

    const timeFilterOptions = [
        { value: "Today", label: "Today" },
        { value: "Week", label: "Week" },
    ];

    const handleAssignFilter = (event: any) => {
        setAssignedFilter(event.target.value);
    };

    const handleTimeFilter = (event: any) => {
        setTimeFilter(event.target.value);
    };

  const handleTaskClick = (item: any, row: any) => {
    const handleConsultation = (): void  => {
      const workDetails = row?.work;
      const payload = {
        customerId: workDetails?.customer_id,
        visitId: workDetails?.visit_id,
        consultationOrder: workDetails?.consultation_order,
        orgId: selectedOrg,
        specialityId: userDetails?.speciality,
        memberId: userDetails?.memberIds,
        role: userRole
      };
      new MedicalProfessionalService(payload)
        .startTask()
        .then((res) => {
          setSkeleton(false);
          navigate(ROUTES.MP.USER_CHAT_HISTORY);
        })
        .catch((err) => {
          setSkeleton(false);
          dispatch(
            commonActions.SHOW_SNACKBAR({
                show: true,
                message: err.message,
                success: false
            })
        );
        });
    }

    return (
      <Typography onClick={()=>handleConsultation()} className="wpx_pointer_style">
        {item}
      </Typography>
    );
  };

    const tableData =
    activeSessionList?.map((item: any, index: number) => ({
      id: index + 1,
      patientName: handleTaskClick(item?.customer?.name, item),
      reasonForVisit: item?.visit?.reason_for_visit,
      dateTime: item.assigned_date,
    })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%" },
        {
            id: "patientName",
            label: t("active_session_list.patient_name"),
            width: "15%",
        },
        {
            id: "reasonForVisit",
            label: t("active_session_list.reason_for_visit"),
            width: "55%",
            className: "wpx_text_style",
        },
        { id: "dateTime", label: t("active_session_list.date_time"), width: "20%" },
    ];


    return (
        <>
            <Container>
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid size={{ xs: 12, sm: 12, md: 5, lg: 3 }}>
                        <InputField
                            value={search}
                            placeHolder={t("active_session_list.search_by_name_aadhaar")}
                            variant="outlined"
                            onChange={(e) => setSearch(e.target.value)}
                            customIcon
                        />
                    </Grid>
                    <Grid size={{ xs: 0, sm: 0, md: 0, lg: 4 }}></Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 3, lg: 2 }}>
                        <Select
                            options={timeFilterOptions}
                            value={timeFilter}
                            onChange={handleTimeFilter}
                            fullWidth
                        />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 12, md: 3, lg: 3 }}>
                        <Select
                            options={assignedFilterOptions}
                            value={assignedFilter}
                            onChange={handleAssignFilter}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                {skeleton ? (
                    <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                        count={15}
                    />
                ) : (
                    <>
                        <Table data={tableData} columns={columns} />
                    </>
                )}
            </Container>
        </>
    );
};

export default ActiveSessionList;
