import { Container, Grid2, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const IncorrectRoute = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ height: "80vh" }}>
      <Grid2 container sx={{ alignItems: "center", justifyContent: "center" }}>
        <Grid2>
          <h1 className="first-four">4</h1>
        </Grid2>
        <Grid2>
          <div className="cog-wheel1">
            <div className="cog1">
              <div className="top"></div>
              <div className="down"></div>
              <div className="left-top"></div>
              <div className="left-down"></div>
              <div className="right-top"></div>
              <div className="right-down"></div>
              <div className="left"></div>
              <div className="right"></div>
            </div>
          </div>
        </Grid2>
        <Grid2>
          <h1 className="second-four">4</h1>
        </Grid2>
      </Grid2>
      <Grid2 container sx={{ alignItems: "center", justifyContent: "center" }}>
        <Typography sx={{ textAlign: "center", color: "#000", fontWeight: 900 }}>{t("common.page_not_found")}</Typography>
      </Grid2>
    </Container>
  );
};

export default IncorrectRoute;
