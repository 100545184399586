import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../../../constants/constants";
import Table from "../../../components/common/table/Table";
import DialogModal from "../../../components/common/modal/DialogModal";
import {
    Box,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/common/button/Button";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InputField from "../../../components/common/input/InputField";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { commonActions } from "../../../store/features/commonSlice";
import { REGEX } from "../../../constants/regex";
import RepresentativeService from "../services/representativeService";
import Skeleton from "../../../components/common/skeleton";

const ManageCSRList = () => {

    const [t] = useTranslation();
    const dispatch = useAppDispatch();

    const organizationDetails = useAppSelector((state) => state.common.curentOrgList);
    const orgId = organizationDetails?.selectOrg?.id ?? "";

    const [csrList, setCsrList] = useState<any>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<any>(true);
    const [csrData, setCsrData] = useState<any>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [emailVaildation, setEmailValidation] = useState<boolean>(false);


    const handleEdit = (user: any) => {
        setShowValidation(false);
        setIsEditModalOpen(true);
        setCsrData(user);
    };

    const handleDelete = (user: any) => {
        setCsrData(user);
        setIsDeleteModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const closeAddDataModal = () => {
        setIsAddModalOpen(false);
    };

    const handleFieldChange = (e: any, fieldName: string) => {
        if (fieldName === "email") {
            if (REGEX.EMAIL_VALIDATION.test(e.target.value)) {
                setEmailValidation(false);
            }
        }
        const tempData = { ...csrData };
        tempData[fieldName] = e.target.value;
        setCsrData(tempData);
    };

    const handleAddBtnClick = () => {
        setShowValidation(false);
        setIsAddModalOpen(true);
        setCsrData(null);
    };

    useEffect(() => {
        // Function to Get the list of all customer support representative in an organization
        const representativeList = () => {
            const payload = {};
            new RepresentativeService(payload).representativeList(orgId)
                .then((res) => {
                    setCsrList(res);
                    setSkeleton(false);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err?.message,
                            success: false
                        })
                    );
                });
        };
        representativeList();
    }, [refreshList, dispatch, orgId]);


    const tableData =
        csrList?.map((item: any, index: number) => ({
            id: (index + 1),
            name: item?.representativeName,
            email: item?.email,
            memberId: item?.id
        })) ?? [];

    const columns = [
        { id: "id", label: t("common.s_no"), width: "10%" },
        { id: "name", label: t("manage_csr.csr_name"), width: "20%" },
        { id: "email", label: t("manage_csr.email"), width: "20%" },
        { id: "actions", label: t("common.action"), width: "10%" }
    ];

    // Function to handle adding a new customer support representative in an organization
    const handleAddRepresentative = () => {
        setShowValidation(true);
        if (!CONSTANTS.EMAIL_REGEX.test(csrData?.email) && csrData?.email) {
            setEmailValidation(true);
        } else {
            setEmailValidation(false);
            if (csrData?.name?.trim() && csrData?.email?.trim()) {
                const payload = {
                    orgId: orgId,
                    name: csrData?.name.trim(),
                    email: csrData?.email
                };
                new RepresentativeService(payload).addRepresentative()
                    .then((res) => {
                        dispatch(
                            commonActions.SHOW_SNACKBAR({
                                show: true,
                                message: res?.message
                            })
                        );
                        setIsAddModalOpen(false);
                        setRefreshList(true);
                    }).catch((err) => {
                        dispatch(
                            commonActions.SHOW_SNACKBAR({
                                show: true,
                                message: err?.message,
                                success: false
                            })
                        );
                        setIsAddModalOpen(false);
                    });

            }
            setRefreshList(false);
        }
    };

    // Function to handle updating a customer support representative in an organization
    const handleEditRepresentative = () => {
        setShowValidation(true);
        if (csrData?.name.trim() && csrData?.email) {
            const payload = {
                memberId: csrData?.memberId,
                name: csrData?.name.trim()
            };
            new RepresentativeService(payload).updateRepresentatve(orgId)
                .then((res) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: res?.message
                        })
                    );
                    setIsEditModalOpen(false);
                    setRefreshList(true);
                }).catch((err) => {
                    dispatch(
                        commonActions.SHOW_SNACKBAR({
                            show: true,
                            message: err?.message,
                            success: false
                        })
                    );
                    setIsEditModalOpen(false);
                });
        }
        setRefreshList(false);
    };

    // Function to handle deleting a customer support representative in an organization
    const handleDeleteRepresentative = () => {
        const payload = { memberId: csrData?.memberId };
        new RepresentativeService(payload).deleteRepresentative(orgId)
            .then((res) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: res?.message
                    })
                );
                setIsDeleteModalOpen(false);
                setRefreshList(true);
            }).catch((err) => {
                dispatch(
                    commonActions.SHOW_SNACKBAR({
                        show: true,
                        message: err?.message,
                        success: false
                    })
                );
            });
        setRefreshList(false);
    };

    
    // Function to handle closing the delete model when not deleting a customer support representative in an organization
    const handleCloseDelete = () => {
        setIsDeleteModalOpen(false);
    };

    return (
        <Container>
            {skeleton ? (
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    count={15}
                />
            ) : (
                <Table
                    data={tableData}
                    columns={columns}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
                // TODO: Pagination need to be added in future

            )}

            <Box className="wpx_modal_btnCenter">
                <Button
                    className="wpx_plus__btn"
                    variant="contained"
                    onClick={handleAddBtnClick}
                    startIcon={<AddIcon />}
                />
            </Box>

            <DialogModal open={isDeleteModalOpen}>
                <Box sx={{ py: 2 }} >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.delete_confirmation_msg")}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            {t("common.msg_to_proceed")}
                        </DialogContentText>
                    </DialogContent>

                    <Box className="wpx_textCenter">
                        <Button
                            className="wpx_save__btn"
                            label={t("common.yes")}
                            variant="contained"
                            startIcon={<CheckRoundedIcon />}
                            onClick={handleDeleteRepresentative}
                        />
                        <Button
                            className="wpx_save__btn"
                            label={t("common.no")}
                            variant="contained"
                            startIcon={<ClearOutlinedIcon />}
                            onClick={handleCloseDelete}
                        />
                    </Box>
                </Box>
            </DialogModal>

            <DialogModal open={isEditModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml: "10px", justifyContent: "space-between" }}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>{t("manage_csr.edit_csr")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleCloseEditModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box component="form" className="wpx_input_field_style">
                            <Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                className=""
                                sx={{ width: "500px" }}
                            >
                                <InputField value={csrData?.name} variant="outlined" label="Name" placeHolder="Enter name" onChange={(e) => { handleFieldChange(e, "name"); }} />
                                {
                                    showValidation && !csrData?.name.trim() ?
                                        <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                        : null
                                }
                                <InputField value={csrData?.email} variant="outlined" label="Email" placeHolder="Enter email" onChange={(e) => { handleFieldChange(e, "email"); }} disabled={true} />
                                {
                                    showValidation && !csrData?.email ?
                                        <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                        : null
                                }
                            </Stack>
                            <Box className="wpx_modal_btnCenter">
                                <Button
                                    className="wpx_save__btn"
                                    variant="contained"
                                    startIcon={<SaveAsIcon />}
                                    label={t("common.save")}
                                    onClick={handleEditRepresentative}
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </DialogModal>

            <DialogModal open={isAddModalOpen}>
                <DialogActions
                    className="wpx_manage__form__action__btn"
                    sx={{ px: 2, mt: 2, ml: "31px", justifyContent: "space-between" }}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>{t("manage_csr.add_csr")}</Typography>
                    <ClearOutlinedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={closeAddDataModal}
                    />
                </DialogActions>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box component="form" className="wpx_input_field_style">
                                <Stack
                                    component="form"
                                    spacing={2}
                                    noValidate
                                    autoComplete="off"
                                    sx={{ width: "500px" }}
                                >
                                    <InputField value={csrData?.name ? csrData?.name : ""} onChange={(e) => { handleFieldChange(e, "name"); }} variant="outlined" label="Name" placeHolder="Enter name" />
                                    {
                                        showValidation && !csrData?.name?.trim() ?
                                            <Typography color="error">{t("manage_csr.enter_name")}</Typography>
                                            : null
                                    }
                                    <InputField value={csrData?.email ? csrData?.email : ""} onChange={(e) => { handleFieldChange(e, "email"); }} variant="outlined" label="Email" placeHolder="Enter email" />
                                    {
                                        showValidation && !csrData?.email?.trim() ?
                                            <Typography color="error">{t("manage_csr.enter_email")}</Typography>
                                            : null
                                    }
                                    {
                                        emailVaildation && csrData?.email?.trim() ? <Typography color="error">{t("common.valid_email")}</Typography>
                                            : null
                                    }
                                </Stack>
                                <Box className="wpx_modal_btnCenter">
                                    <Button
                                        className="wpx_save__btn"
                                        variant="contained"
                                        startIcon={<SaveAsIcon />}
                                        label={t("common.save")}
                                        onClick={handleAddRepresentative}
                                    />
                                </Box>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </DialogContent>
            </DialogModal>
        </Container>
    );
};

export default ManageCSRList;
